import { useMemo, useState } from "react";
import { HorizontalSlides } from "./HorizontalSlides";
import { HorizontalSlidesContainerProps } from "./types";

const HorizontalSlidesContainer: React.FC<HorizontalSlidesContainerProps> = ({pagesOptions}) => {
  const [page, setPage] = useState<number>(0);
  const pagesAmount = useMemo(() => pagesOptions.length, [pagesOptions]);

  const swapPage = (direction: number) => {
    setPage((page) => (page + direction) % pagesAmount);
  };

  return <HorizontalSlides pagesOptions={pagesOptions} page={page} swapPage={swapPage}/>
};

export default HorizontalSlidesContainer;