import { connect } from 'react-redux';

import { RootState } from '../../../../store';
import { selectIsDesktop } from '../../../../store/settingsSlice/selectors';

import { SettingsPanel } from './SettingsPanel';

function mapStateToProps(state: RootState) {
  return {
    isDesktop: selectIsDesktop(state),
  };
}

export default connect(mapStateToProps)(SettingsPanel);
