export const MIN_DISPLACEMENT_OF_PRIMARY_BARS = 32;

export const SCORE_FONT_SIZE = {
  emphasize: 36,
  primaryWithMaxScore: 18,
  primary: 16,
};

export const COLORS = {
  primary: {
    light: '#8FADC8',
    dark: '#62819e'
  },
  emphasize: {
    light: '#4BB34B',
    dark: '#3c8f4a'
  },
  background: {
    light: '#F2F3F5',
    dark: '#363738'
  }
};
