import { getWallImage, hash } from './canvas';
import { addImageToUser } from '../../../../../../api/VKWebApi';
import { WALL_BACKGROUND_IMAGE_URL } from '../constants';

export const uploadShareResultImageToVk = async (
  factorName: string,
  description: string,
  generatedImageSrc: string,
  accessToken: string,
  appId: number,
) => {
  const imageBinaryData = await getWallImage(
    factorName,
    description,
    WALL_BACKGROUND_IMAGE_URL,
    generatedImageSrc
  );

  if (!imageBinaryData || !imageBinaryData.file) {
    return;
  }

  const { file: image, base64 } = imageBinaryData;

  const imageHash = await hash(base64);

  const response: any = await addImageToUser(
    accessToken,
    image,
    imageHash,
    'Психологические тесты',
    `Узнай себя лучше с помощью наших тестов!\n https://vk.com/${Boolean(appId) ? `app${appId}` : 'ticspsytests'}`,
  );

  if (!response) {
    throw Error('Photo loading error');
  }

  return response;
};
