import React from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { startPassing } from '../../../store/testSlice';

import { usePopout } from '../../../hooks/usePopout';

import { POPOUT } from '../../../constants/Popout';

import { selectTestFullDescription, selectTestShortName } from '../../../store/testSlice/testSelectors';

import passingsApi from '../../../api/PassingsApi';

import { FullDescription } from './FullDescription';

const FullDescriptionContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setPopout, resetPopout } = usePopout();

  const testShortName = useSelector(selectTestShortName) || '';
  const fullDescription = useSelector(selectTestFullDescription);

  const handleStart = async () => {
    setPopout(POPOUT.SMART_SPINNER);

    await passingsApi.startPassing(testShortName); 
    dispatch(startPassing());
    
    resetPopout();
  };

  return (
    <FullDescription
      text={fullDescription}
      onStart={handleStart}
    />
  );
};

export default FullDescriptionContainer;
