import { useEffect } from 'react';
import { usePlatform, Platform } from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';

export const useIOS = () => {
    const platform = usePlatform();

    useEffect(() => {
        if (platform === Platform.IOS) {
            bridge.send('VKWebAppSetSwipeSettings', { history: true });
        }
    }, [platform]);
};
