import { connect } from 'react-redux';

import { ViewType } from '../constants/View';
import { PanelType } from '../constants/Panel';
import { ModalType } from '../constants/Modal';
import { useRouter } from '../hooks/useRouter';
import { selectView, selectPanel, selectModal } from '../store/routerSlice/routerSelectors';
import { selectIsDesktop, selectHasHeader } from '../store/settingsSlice/selectors';
import { RootState } from '../store';

import { MainViews } from './MainViews';

type ContainerPropsType = {
    isDesktop: boolean;
    hasHeader: boolean;
    activeView: ViewType;
    activePanel: PanelType;
    activeModal: ModalType | null;
    areNotificationsEnabled?: boolean;
};

const MainViewsContainer: React.FC<ContainerPropsType> = ({
    areNotificationsEnabled,
    activeView,
    activePanel,
    ...props
}) => {
    const router = useRouter();

    return (
        <MainViews
            {...props}
            activeView={activeView}
            activePanel={activePanel}
            onModalClose={router.back}
        />
    );
};

function mapStateToProps(state: RootState) {
    return {
        isDesktop: selectIsDesktop(state),
        hasHeader: selectHasHeader(state),
        activeView: selectView(state),
        activePanel: selectPanel(state),
        activeModal: selectModal(state)
    };
}

export default connect(mapStateToProps)(MainViewsContainer);
