import React from 'react';

import styles from './FeatureCellContent.module.css';
import { CompactCell } from '../../../CompactCell';
import { FeatureCellContentProps } from './types';

export const FeatureCellContent: React.FC<FeatureCellContentProps> = ({ feature }) => {
  return (
    <div className={styles.container}>
      {
        feature.data.map(({ photo_src, name, photo_placeholder }) => (
          <CompactCell
            title={name}
            photoSrc={photo_src}
            photoPlaceholderSrc={photo_placeholder}
            className={styles.cell}
          />
        ))
      }
    </div>
  );
};
