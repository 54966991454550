import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

import { compareStringifiedObjects } from '../../utils/compareStringifiedObjects';

export const selectTest = (state: RootState) => state.test.data;

export const selectLoading = (state: RootState) => state.test.loading;

export const selectError = (state: RootState) => state.test.error;

export const selectTestShortName = createSelector(
  selectTest,
  (test) => test?.short_name,
);

export const selectTestFullName = createSelector(
  selectTest,
  (test) => test?.full_name,
);

export const selectIsPassingStarted = createSelector(
  selectTest,
  (test) => test?.is_passing_started,
);

export const selectTestOptions = createSelector(
  selectTest,
  (test) => test?.options,
);

export const selectTestCanRedo = createSelector(
  selectTestOptions,
  (options) => options?.can_redo,
);

export const selectTestTokenScope = createSelector(
  selectTestOptions,
  (options) => options?.token_scope,
);

export const selectTestAdditionalInfo = createSelector(
  selectTest,
  (test) => test?.additional_info,
);

export const selectAnsweredQuestionsCount = createSelector(
  selectTestAdditionalInfo,
  (additionalInfo) => additionalInfo?.answered_questions_count,
);

export const selectTestDecorations = createSelector(
  selectTest,
  (test) => test?.decorations,
);

export const selectTestInstruction = createSelector(
  selectTestDecorations,
  (decorations) => decorations?.instruction,
);

export const selectTestFullDescription = createSelector(
  selectTestDecorations,
  (decorations) => decorations?.full_description,
);

export const selectTestQuestions = createSelector(
  selectTest,
  (test) => test?.questions,
);

export const selectTestQuestionsCount = createSelector(
  selectTestQuestions,
  (questions) => questions?.length,
);

export const selectAcceptedQuestions = createSelector(
  selectTestQuestions,
  (questions) => questions?.filter(({ state }) => state === 'accepted'),
);

export const selectAcceptedQuestionsCount = createSelector(
  selectAcceptedQuestions,
  (questions) => questions?.length,
);

export const selectSkippedQuestions = createSelector(
  selectTestQuestions,
  (questions) => questions?.filter(({ state }) => state === 'skipped'),
);

export const selectSkippedQuestionsCount = createSelector(
  selectSkippedQuestions,
  (questions) => questions?.length,
);

export const selectAcceptedOrSkippedQuestionsCount = createSelector(
  selectTestQuestions,
  (questions) => questions?.filter(({ state }) => ['accepted', 'skipped'].includes(state)).length,
);

export const selectModifiedQuestions = createSelector(
  selectTestQuestions,
  (questions) => questions?.filter(({ state }) => state === 'modified'),
);

export const selectModifiedQuestionsCount = createSelector(
  selectModifiedQuestions,
  (questions) => questions?.length,
);

export const selectQuestionById = (state: RootState, questionId: number) => {
  return selectTestQuestions(state)?.find((question) => question.q_id === questionId);
};

export const selectQuestionIndexById = (state: RootState, questionId: number) => {
  return selectTestQuestions(state)?.findIndex((question) => question.q_id === questionId);
};

export const selectFirstQuestionIndex = createSelector(
  selectTestQuestions,
  (questions) => questions?.findIndex((question) => !(['accepted', 'skipped'].includes(question.state))),
);

export const areDisplayConditionsFulfilled = createSelector(
  selectTestQuestions,
  (_: RootState, index: number) => index,
  (questions, index) => {
    if (!questions || !questions[index]) {
      return false;
    }

    const displayConditions = questions[index].display_conditions;

    if (!displayConditions) {
      return true;
    }

    for (const displayCondition of displayConditions) {
      const { q_id, answers } = displayCondition;
      const conditionQuestion = questions.find((question) => question.q_id === q_id);

      if (!conditionQuestion) {
        continue;
      }

      const isConditionFulfilled = answers.every(
        (conditionAnswer) => conditionQuestion.user_answers.filter(
          (userAnswer) => compareStringifiedObjects(conditionAnswer, userAnswer)
        ).length !== 0
      );

      if (!isConditionFulfilled) {
        return false;
      }
    }

    return true;
  },
);

export const selectQuestionNumber = createSelector(
  selectTestQuestions,
  (_: RootState, q_id: number) => q_id,
  (questions, q_id) => {
    let position = 1;

    if (!questions) {
      return position;
    }
    
    const questionIndex = questions.findIndex((question) => question.q_id === q_id);

    for (let index = 0; index < questionIndex; index++) {
      if (questions[index].state === 'accepted') {
        position++;
      }
    }
    
    return position;
  },
);
