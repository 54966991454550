import {
  SubnavigationBar,
  SubnavigationButton,
} from '@vkontakte/vkui';

import { ViewType } from '../../../constants/View';

export type DesktopNavigationOptionType = {
  value: string | number | undefined;
  text: string | undefined;
  label: React.ReactElement;
  action: () => void;
};

export type DesktopNavigationRestType = {
  activeView: ViewType;
  id: string;
};

export type DesktopNavigationPropsType = { options: DesktopNavigationOptionType[] } & DesktopNavigationRestType;

export const DesktopNavigation: React.FC<DesktopNavigationPropsType> = ({
  options,
  activeView,
  id,
}) => {
  return (
    <SubnavigationBar mode='fixed' id={id}>
      {options.map(({ value, text, label, action }) => (
        <SubnavigationButton
          before={label}
          size='l'
          textLevel='1'
          selected={value === activeView}
          onClick={action}
          key={`${value}_view`}
        >
          {text}
        </SubnavigationButton>
      ))}
    </SubnavigationBar>
  );
};
