import bridge from '@vkontakte/vk-bridge';
import { apiDataType, ImageUploadingToVkServersResponse } from './types';

export const getCallApiMethod = (accessToken: string) => (
  method: string,
  data: apiDataType
) => {
  return bridge.send('VKWebAppCallAPIMethod', {
    method,
    request_id: 'tics42',
    params: {
      v: '5.131',
      access_token: accessToken,
      ...data
    },
  });
};

export const getFromVkStorage = async (
  key: string,
  callApiMethod: (method: string, data: apiDataType) => Promise<{ [key: string]: any }>
): Promise<string> => {
  const { response } = await callApiMethod('storage.get', { key: key });
  const { value } = response.find((obj: any) => obj.key === key);
  return value;
};

export const getAlbumId = async (
  albumKey: string,
  albumTitle: string,
  albumDescription: string,
  callApiMethod: (method: string, data: apiDataType) => Promise<{ [key: string]: any }>
): Promise<string> => {
  let albumId = await getFromVkStorage(albumKey, callApiMethod);

  if (albumId === '') {
    const { response } = await callApiMethod('photos.createAlbum', {
      title: albumTitle,
      description: albumDescription
    });

    albumId = response.id;
    await callApiMethod('storage.set', { key: albumKey, value: albumId });
  }

  return albumId;
};

export const uploadImageToVkServers = async (
  image: any,
  albumId: string,
  callApiMethod: (method: string, data: apiDataType) => Promise<{ [key: string]: any }>
): Promise<ImageUploadingToVkServersResponse> => {
  const { response: { upload_url } } = await callApiMethod('photos.getUploadServer', { album_id: albumId });

  const formData = new FormData();
  formData.append('file', image, 'picture.png');
  formData.append('upload_url', upload_url);

  const response = await fetch('api/images/add-to-vk', {
    method: 'POST',
    body: formData
  });
  return await response.json();
};
