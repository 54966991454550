import { FactorType } from '../types';

const compareFactors = (a: FactorType, b: FactorType) => {
  if (b.score !== a.score) {
    return b.score - a.score;
  }
  return b.priority - a.priority;
};

export default compareFactors;
