import React from 'react';
import dayjs from 'dayjs';

import {
  Div,
  CardGrid,
  Placeholder,
} from '@vkontakte/vkui';
import { Icon56DocumentOutline } from '@vkontakte/icons';

import { GroupHeader } from '../../common/GroupHeader';

import { ResultType } from '../../../types/result.type';
import { ResultCard } from '../ResultCard';

export type ResultListPropsType = {
  results: ResultType[];
};

export const ResultList: React.FC<ResultListPropsType> = ({ results }) => {
  return (
    <>
      <Div style={{ paddingTop: 0, paddingBottom: 6 }}>
        <GroupHeader>
          Результаты последних прохождений
        </GroupHeader>
      </Div>
      {Boolean(results.length) ? (
        <CardGrid size='l'>
          {results.map(({ test_short_name, completion_date }) => (
            <ResultCard
              key={test_short_name}
              testShortName={test_short_name}
              completionDate={dayjs(completion_date).format('HH:mm DD.MM.YYYY')}
            />
          ))}
        </CardGrid>
      ) : (
        <Placeholder
          icon={<Icon56DocumentOutline/>}
          header='Вы пока не прошли ни одного теста...&#128546;'
        />
      )}
    </>
  );
};
