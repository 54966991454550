import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import resultsApi, { GetResultsQueryParams } from '../../api/ResultsApi';
import { ResultType } from '../../types/result.type';

import { isError } from '../../utils/isError';

type ResultsStateType = {
  data: ResultType[],
  loading: boolean;
  error: string | null;
};

export const fetchResults = createAsyncThunk<ResultType[] | undefined, GetResultsQueryParams | undefined, { rejectValue: string }>(
  'results/fetchResults',
  async function (queryParams, { rejectWithValue }) {
    try {
      const data = await resultsApi.getResults(queryParams) as ResultType[];
      data.sort((a, b) => b.completion_date - a.completion_date);
      
      return data;
    }
    catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

const resultsInitialState: ResultsStateType = {
  data: [],
  loading: false,
  error: null,
};

const resultsSlice = createSlice({
  name: 'results',
  initialState: resultsInitialState,
  reducers: {
    updateResultFromArray(state, action: PayloadAction<ResultType>) {
      const resultIndex = state.data.findIndex(({ test_short_name }) => test_short_name === action.payload.test_short_name);

      if (resultIndex !== -1) {
        state.data = [
          action.payload,
          ...state.data.slice(0, resultIndex),
          ...state.data.slice(resultIndex + 1),
        ];
      } else {
        state.data = [
          action.payload,
          ...state.data,
        ];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResults.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchResults.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload;
        }
        state.loading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export const { updateResultFromArray } = resultsSlice.actions;

export default resultsSlice.reducer;
