import React from 'react';
import { ContentCard } from '@vkontakte/vkui';

import defaultImageLight from '../../../images/testDefaultImageLight.png';
import defaultImageDark from '../../../images/testDefaultImageDark.png';

import { AddingTestCardProps } from './AddingsTestCard.types';

import styles from './AddingTestCard.module.css';

export const AddingsTestCard: React.FC<AddingTestCardProps> = ({
  isDesktop,
  appearance,
  onClick,
}) => {
  const isLight = appearance === 'light';

  return (
    <ContentCard
      src={isLight ? defaultImageLight : defaultImageDark}
      maxHeight={isDesktop ? 140 : 180}
      header={(
        <span className={styles.cardHeader}>
          Добавить свой тест
        </span>
      )}
      text='Вы можете добавить в приложение собственный тест. Для этого достаточно нажать на карточку и оставить заявку.'
      onClick={onClick}
      data-testid='AddingTestCard'
    />
  );
};
