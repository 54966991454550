import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from './useAppDispatch';

import { updateLocation } from '../store/routerSlice';
import { selectLocation } from '../store/routerSlice/routerSelectors';

import { DEFAULT_LOCATION, LocationType } from '../constants/History';
import { STRUCTURE } from '../constants/Structure';

import { compareStringifiedObjects } from '../utils/compareStringifiedObjects';

export type UseRouterType = {
    forward: (recievedLocation: Partial<LocationType>, isSilent?: boolean) => Promise<void>;
    back: () => void;
    replace: (recievedLocation: Partial<LocationType>, isSilent?: boolean) => Promise<void>;
};

export const useRouter = (): UseRouterType => {
    const location = useSelector(selectLocation);
    const dispatch = useAppDispatch();
  
    const onPopState = useCallback(
        (event: PopStateEvent) => {
            const updatedLocation: LocationType = event.state || DEFAULT_LOCATION;
            
            dispatch(updateLocation(updatedLocation));
        },
        [dispatch]
    );

    const forward = useCallback(
        async (recievedLocation: Partial<LocationType>, isSilent: boolean = false) => {
            const updatedLocation: LocationType = {
                ...location,
                ...(recievedLocation.view && (
                    {
                        panel: STRUCTURE.VIEWS[recievedLocation.view].DEFAULT_PANEL,
                        modal: STRUCTURE.VIEWS[recievedLocation.view].DEFAULT_MODAL,
                    }
                )),
                ...recievedLocation
            };

            if (!compareStringifiedObjects(location, updatedLocation)) {
                updatedLocation.length += 1;
                window.history.pushState(updatedLocation, '');
            
                if (!isSilent) {
                    dispatch(updateLocation(updatedLocation));
                }
            }
        },
        [dispatch, location]
    );
  
    const back = useCallback(() => window.history.back(), []);

    const replace = useCallback(
        async (recievedLocation: Partial<LocationType>, isSilent: boolean = false) => {
            const updatedLocation = {
                ...location,
                ...recievedLocation,
            };

            window.history.replaceState(updatedLocation, '');
        
            if (!isSilent) {
                dispatch(updateLocation(updatedLocation));
            }
        },
        [dispatch, location]
    );

    useEffect(
        () => {
            window.addEventListener('popstate', onPopState);
            
            return () => window.removeEventListener('popstate', onPopState);
        },
        [onPopState]
    );

    return {
        forward,
        back,
        replace
    };
};
