import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import * as api from '../../api/AppConfig';
import { isError } from '../../utils/isError';
import { AppConfig } from '../../types/appconfig.type';

import { APP_CONFIG_INITIAL_STATE, DEFAULT_APP_CONFIG } from './constants';

export const fetchAppConfig = createAsyncThunk<AppConfig | null, undefined, { rejectValue: string }>(
    'appConfig/fetchAppConfig',
    async function(_, { rejectWithValue, dispatch }) {
        try {
            const data = await api.getAppConfig();

            dispatch(updateAppConfig(data));

            return data;
        }
        catch (error) {
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue('UNKNOWN');
        }
    }
);

const appConfigSlice = createSlice({
    name: 'appConfig',
    initialState: APP_CONFIG_INITIAL_STATE,
    reducers: {
        updateAppConfig(state, action: PayloadAction<AppConfig | null>) {
            if (!action.payload) {
                state.data = DEFAULT_APP_CONFIG;

                return;
            }

            const {
                version,
                announcements = DEFAULT_APP_CONFIG.announcements,
                features = DEFAULT_APP_CONFIG.features
            } = action.payload;

            state.data = {
                version,
                announcements,
                features
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppConfig.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAppConfig.fulfilled, (state) => {
                state.loading = false;
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
});

export const { updateAppConfig } = appConfigSlice.actions;

export default appConfigSlice.reducer;
