import React from 'react';

import { Button, classNames } from '@vkontakte/vkui';

import { LightButtonProps } from './types';
import styles from './LightButton.module.css';

export const LightButton: React.FC<LightButtonProps> = ({
  text,
  before,
  stretched,
  onClick,
  appearance
}) => {
  return (
    <Button
      onClick={onClick}
      stretched={stretched}
      before={before}
      appearance={appearance === 'light' ? 'neutral' : 'accent'}
      className={classNames(
        styles.withBorder,
        appearance === 'light' && styles.white
      )}
    >
      {text}
    </Button>
  );
};
