import React, { FormEvent } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import {
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Button,
  Group,
  Div,
  FormLayout,
  FormStatus,
  FormLayoutGroup,
  FormItem,
  Input,
  Textarea,
  Text,
} from '@vkontakte/vkui';

import { GroupHeader } from '../../../../components/common/GroupHeader';
import { TextWithAsterisk } from '../../../../components/common/TextWithAsterisk';

import { PanelType } from '../../../../constants/Panel';
import { AddingTestFormType } from '../../../../types/addingTestForm.type';

import { isEmailValid } from '../../../../utils/isEmailValid';

const ADDING_TEST_FORM = {
  FIRST_NAME: {
    field: 'firstName',
    label: <TextWithAsterisk>Имя</TextWithAsterisk>,
  },
  LAST_NAME: {
    field: 'lastName',
    label: <TextWithAsterisk>Фамилия</TextWithAsterisk>,
  },
  ORGANIZATION: {
    field: 'organization',
    label: 'Организация',
    placeholder: 'Введите название Вашей организации (если таковая имеется)',
  },
  EMAIL: {
    field: 'email',
    label: <TextWithAsterisk>Электронная почта</TextWithAsterisk>,
    placeholder: 'Введите email для связи с Вами',
    errorMessage: 'Некорректный адрес электронной почты',
  },
  TEXT: {
    field: 'text',
    label: <TextWithAsterisk>Текст заявки</TextWithAsterisk>,
    placeholder: 'Расскажите о тесте, который Вы хотели бы добавить в приложение',
  },
};

export type AddingTestFormPanelPropsType = AddingTestFormType & {
  id: PanelType;
  onBackClick: () => void;
  onSubmit: (event: FormEvent) => void;
  updateField: (field: string, value: string) => void;
  formError: string | null;
  wasSent: boolean;
};

export const AddingTestFormPanel: React.FC<AddingTestFormPanelPropsType> = ({
  id,
  onBackClick,
  onSubmit,
  updateField,
  firstName,
  lastName,
  organization,
  email,
  text,
  formError,
  wasSent,
}) => {
  return (
    <Panel id={id}>
      <PanelHeader
        before={<PanelHeaderBack onClick={onBackClick} />}
      >
        Добавление теста
      </PanelHeader>
      <Group>
        <Div>
          <GroupHeader>Заявка на добавление теста</GroupHeader>
          <Text style={{ marginBottom: '-1.2em' }}>
            <ReactMarkdown>
              Указанная информация будет отправлена на почту **mail@dscs.pro** для связи с Вами
            </ReactMarkdown>
          </Text>
        </Div>
        {wasSent && (
          <FormStatus 
            header='Данные успешно отправлены'
            mode="default"
          >
            Мы постарается ответить Вам в самое ближайшее время
          </FormStatus>
        )}
        {Boolean(formError) && (
          <FormStatus
            header='При отправке данных произошла ошибка'
            mode='error'
          >
            Пожалуйста, попробуйте отправить заявку заново
          </FormStatus>
        )}
        <FormLayout onSubmit={onSubmit}>
          <FormLayoutGroup mode='horizontal'>
            <FormItem top={ADDING_TEST_FORM.FIRST_NAME.label}>
              <Input
                name={ADDING_TEST_FORM.FIRST_NAME.field}
                value={firstName}
                onChange={(event) => updateField(ADDING_TEST_FORM.FIRST_NAME.field, event.target.value)}
              />
            </FormItem>
            <FormItem top={ADDING_TEST_FORM.LAST_NAME.label}>
              <Input 
                name={ADDING_TEST_FORM.LAST_NAME.field}
                value={lastName}
                onChange={(event) => updateField(ADDING_TEST_FORM.LAST_NAME.field, event.target.value)}
              />
            </FormItem>
          </FormLayoutGroup>
          <FormItem top={ADDING_TEST_FORM.ORGANIZATION.label}>
            <Input 
              name={ADDING_TEST_FORM.ORGANIZATION.field}
              value={organization}
              onChange={(event) => updateField(ADDING_TEST_FORM.ORGANIZATION.field, event.target.value)}
              placeholder={ADDING_TEST_FORM.ORGANIZATION.placeholder}
            />
          </FormItem>
          <FormItem
            top={ADDING_TEST_FORM.EMAIL.label}
            status={!email ? 'default' : (isEmailValid(email) ? 'valid' : 'error')}
            bottom={!email ? '' : (isEmailValid(email) ? '' : ADDING_TEST_FORM.EMAIL.errorMessage)}
          >
            <Input
              type='email'
              name={ADDING_TEST_FORM.EMAIL.field}
              value={email}
              onChange={(event) => updateField(ADDING_TEST_FORM.EMAIL.field, event.target.value)}
              placeholder={ADDING_TEST_FORM.EMAIL.placeholder}
            />
          </FormItem>
          <FormItem top={ADDING_TEST_FORM.TEXT.label}>
            <Textarea
              name={ADDING_TEST_FORM.TEXT.field}
              value={text}
              onChange={(event) => updateField(ADDING_TEST_FORM.TEXT.field, event.target.value)}
              placeholder={ADDING_TEST_FORM.TEXT.placeholder}
              rows={2}
            />
          </FormItem>
          <FormItem>
            <Button size='l' stretched mode='secondary' type='submit'>
              Отправить
            </Button>
          </FormItem>
        </FormLayout>
      </Group>
    </Panel>
  );
};
