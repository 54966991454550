import React from 'react';
import ReactMarkdown from 'react-markdown';

import {
  ModalPage,
  Div,
  ModalPageHeader,
  PanelHeaderButton,
  Platform,
} from '@vkontakte/vkui';
import { Icon24Cancel } from '@vkontakte/icons';

import { ModalType } from '../../../../constants/Modal';

import styles from './InstructionModal.module.css';

export type InstructionModalPropsType = {
  id: ModalType;
  instruction: string | undefined;
  platform: string;
  isDesktop: boolean;
  onBackClick: () => void;
};

export const InstructionModal: React.FC<InstructionModalPropsType> = ({
  id,
  instruction,
  platform,
  isDesktop,
  onBackClick,
}) => {
  if (!instruction) {
    return null;
  }

  const postScriptum = '\n\n**Вы всегда можете вернуться к инструкции, нажав на значок справа от названия теста.**';

  return (
    <ModalPage
      id={id}
      settlingHeight={100}
      header={(
        <ModalPageHeader
          before={(
            <>
              {!isDesktop && (platform === Platform.ANDROID || platform === Platform.VKCOM) && (
                <PanelHeaderButton
                  onClick={onBackClick}
                  aria-label='Закрыть инструкцию'
                >
                  <Icon24Cancel />
                </PanelHeaderButton>
              )}
            </>
          )}
          after={(
            <>
              {platform === Platform.IOS && (
                <PanelHeaderButton
                  onClick={onBackClick}
                  aria-label='Закрыть инструкцию'
                >
                  Готово
                </PanelHeaderButton>
              )}
            </>
          )}
        >
          Инструкция
        </ModalPageHeader>
      )}
    >
      <Div>
        <ReactMarkdown className={styles.instruction}>
          {instruction + postScriptum}
        </ReactMarkdown>
      </Div>
    </ModalPage>
  );
};
