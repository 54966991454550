import { connect } from 'react-redux';

import { RootState } from '../../../store';
import { selectTests } from '../../../store/testsSlice/testsSelectors';
import { selectIsDesktop } from '../../../store/settingsSlice/selectors';

import { TestList } from './TestList';

function mapStateToProps(state: RootState) {
  return {
    tests: selectTests(state),
    isDesktop: selectIsDesktop(state),
  };
}

export default connect(mapStateToProps)(TestList);
