import React from 'react';

import { ShareGollandResultModal } from './ShareGollandResultModal';
import { useShareInStory, useShareOnWall } from './hooks';
import { ShareGollandResultModalContainerProps } from './types';

const ShareGollandResultModalContainer: React.FC<ShareGollandResultModalContainerProps> = (props) => {
  const shareOnWall = useShareOnWall();
  const shareResultInStory = useShareInStory();

  return (
    <ShareGollandResultModal
      {...props}
      shareResulInStory={shareResultInStory}
      shareResulOnWall={shareOnWall}
    />
  );
};

export default ShareGollandResultModalContainer;
