import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';

export const selectAppConfig = (state: RootState) => state.appConfig.data;

export const selectAnnouncements = createSelector(
    selectAppConfig,
    (appConfig) => appConfig.announcements,
);

export const selectAvailableAnnouncement = createSelector(
    selectAnnouncements,
    (announcements) => announcements?.find(({ is_available }) => is_available) || null
);
