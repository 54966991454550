import { useEffect, useCallback } from 'react';

import { fetchAppConfig } from '../store/appConfigSlice';

import { useAppDispatch } from './useAppDispatch';

const FETCH_DELAY = 3 * 60 * 1000;

export const useAppConfig = () => {
    const dispatch = useAppDispatch();

    const refetch = useCallback(
        () => {
            dispatch(fetchAppConfig());
            setTimeout(() => refetch(), FETCH_DELAY);
        },
        []
    );

    useEffect(() => {
        refetch();
    }, []);
};
