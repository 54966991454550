import React from 'react';
import { connect } from 'react-redux';

import { useRouter } from '../../../../hooks/useRouter';

import { RootState } from '../../../../store';
import { selectResult } from '../../../../store/resultSlice/resultSliceSelectors';

import { PanelType } from '../../../../constants/Panel';
import { ResultItemType } from '../../../../types/result.type';

import { ResultPanel } from './ResultPanel';

type ContainerPropsType = {
  id: PanelType;
  results?: ResultItemType[];
};

const ResultPanelContainer: React.FC<ContainerPropsType> = (props) => {
  const router = useRouter();

  return (
    <ResultPanel
      onBackClick={router.back}
      {...props}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    results: selectResult(state),
  };
}

export default connect(mapStateToProps)(ResultPanelContainer);
