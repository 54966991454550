import { View, Panel, PanelHeader, Group, Div } from '@vkontakte/vkui';
import React from 'react';

import { PANEL } from '../../constants/Panel';

import { Banner } from './Banner';
import { NotificationViewProps } from './types';

export const NotificationView: React.FC<NotificationViewProps> = ({ id }) => {
    return (
        <View id={id} activePanel={PANEL.NOTIFICATION}>
            <Panel id={PANEL.NOTIFICATION}>
                <PanelHeader>Уведомления</PanelHeader>
                <Group>
                    <Div>
                        <Banner />
                    </Div>
                </Group>
            </Panel>
        </View>
  );
};
