import React, { useEffect, useMemo, useState } from 'react';
import Animate from 'react-smooth';
import { classNames, Footnote } from '@vkontakte/vkui';

import styles from './Bar.module.css';
import { BarProps } from './types';
import { COLORS, SCORE_FONT_SIZE } from './contstants';
import { calculateWidth } from './utils';

export const Bar: React.FC<BarProps> = ({
  type,
  score,
  label,
  minBarWidth,
  chartWidth,
  maxScoreInChart,
  minScoreInChart,
  appearance,
}) => {
  const isPrimary = useMemo(() => type === 'primary', [type]);
  const width = useMemo(
    () => calculateWidth(
      score,
      isPrimary,
      minBarWidth,
      chartWidth,
      minScoreInChart,
      maxScoreInChart
    ),
    [chartWidth, isPrimary, maxScoreInChart, minBarWidth, minScoreInChart, score]
  );

  const color = useMemo(
    () => score === maxScoreInChart ? COLORS.emphasize[appearance] : COLORS.primary[appearance],
    [score, maxScoreInChart, appearance]
  );

  const [scoreFontSize, setScoreFontSize] = useState(SCORE_FONT_SIZE.primary);

  useEffect(() => {
    if (score === maxScoreInChart) {
      setScoreFontSize(isPrimary ? SCORE_FONT_SIZE.primaryWithMaxScore : SCORE_FONT_SIZE.emphasize);
    }
  }, [score, maxScoreInChart, isPrimary]);

  return (
    <div
      className={styles.rounded}
      style={{ width: chartWidth, backgroundColor: COLORS.background[appearance] }}
    >
      <Animate to={{ width: width }} from={{ width: minBarWidth }}>
        {/* Выделил в отдельный блок, чтобы правильно считалась ширина */}
        <div
          className={styles.rounded}
          style={{ backgroundColor: color }}
        >
          <div
            className={classNames(
              styles.container,
              isPrimary ? styles.primary : styles.emphasized
            )}
          >
            <span
              className={classNames(
                styles.text,
                styles.score,
                isPrimary ? styles.primary : styles.emphasized
              )}
              style={{ fontSize: scoreFontSize }}
            >
              {score}
            </span>
            <Footnote className={styles.text}>
              {label}
            </Footnote>
          </div>
        </div>
      </Animate>
    </div>
  );
};
