type FlatObject = {
  [key: string]: number | string | boolean | null | undefined;
};

export const serializeToQueryParams = (flatObject: FlatObject) => {
  const params = [];

  for (const key in flatObject) {
    if (
      flatObject.hasOwnProperty(key) && 
      flatObject[key] !== null && 
      flatObject[key] !== undefined &&
      flatObject[key] !== ''
    ) {
      params.push(`${encodeURIComponent(key)}=${encodeURIComponent(flatObject[key]!)}`);
    }
  }
  
  return params.join('&');
};