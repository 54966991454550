export const convertToSex = (sexNumber: 0 | 1 | 2) => {
  switch(sexNumber) {
    case 0:
      return 'none';
    case 1:
      return 'female';
    case 2:
      return 'male';
  }
};
