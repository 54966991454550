import { connect } from 'react-redux';

import { RootState } from '../../../../../store';
import { selectAppearance } from '../../../../../store/settingsSlice/selectors';

import { LightButton } from './LightButton';

function mapStateToProps(state: RootState) {
  return {
    appearance: selectAppearance(state),
  };
}

export default connect(mapStateToProps)(LightButton);
