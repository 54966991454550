export const mergeArrays = (array1: any[], array2: any[]) => {
  const mergedArrays = [...array1];

  array2.forEach((item) => {
    if (!mergedArrays.includes(item)) {
      mergedArrays.push(item);
    }
  });

  return mergedArrays;
};
