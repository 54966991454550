import React, { ReactNode } from 'react';

import styles from './LoadingText.module.css';

export type LoadingTextPropsType = {
  children: ReactNode;
};

export const LoadingText: React.FC<LoadingTextPropsType> = ({ children, ...props }) => {
  return (
    <span
      {...props}
      className={styles.loading}
    >
      {children}
    </span>
  );
};
