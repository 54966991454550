import React from 'react';
import { connect, useSelector } from 'react-redux';

import { RootState } from '../../../../store';

import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useTest } from '../../../../hooks/useTest';
import { useRouter } from '../../../../hooks/useRouter';
import { usePopout } from '../../../../hooks/usePopout';
import { useSetAccessToken } from '../../../../hooks/useSetAccessToken';

import { CurrentTestPanel } from './CurrentTestPanel';

import {
  selectQuestionNumber,
  selectIsPassingStarted,
  selectTestFullDescription,
} from '../../../../store/testSlice/testSelectors';
import {
  selectHasInitialInstruction,
  selectHasProgressBar,
  selectIsDesktop,
} from '../../../../store/settingsSlice/selectors';
import { selectHasRequiredTokenScope } from '../../../../store/userSlice/userSelectors';

import { PanelType } from '../../../../constants/Panel';
import { MODAL } from '../../../../constants/Modal';
import { POPOUT } from '../../../../constants/Popout';

type ContainerPropsType = {
  id: PanelType;
  hasProgressBar: boolean;
  isDesktop: boolean;
};

const CurrentTestPanelContainer: React.FC<ContainerPropsType> = (props) => {
  const dispatch = useAppDispatch();

  const router = useRouter();
  const setInstruction = () => router.forward({ modal: MODAL.INSTRUCTION });

  const setAccessToken = useSetAccessToken();
  const hasRequiredTokenScope = useSelector(selectHasRequiredTokenScope);
  const { setPopout } = usePopout();

  const setAccessTokenWithWarning = async () => {
    if (!hasRequiredTokenScope) {
      dispatch(setPopout(POPOUT.TOKEN_SCOPE_ALERT));
      return;
    }
    await setAccessToken();
  };

  const {
    question,
    answer,
    moveForward,
    moveBack,
  } = useTest(router);

  const questionNumber = useSelector((state: RootState) => selectQuestionNumber(state, question.q_id));

  const hasInitialInstruction = (
    useSelector(selectHasInitialInstruction) &&
    questionNumber === 1 &&
    question.state === 'unanswered' 
  );

  const isPassingStarted = useSelector(selectIsPassingStarted);
  const fullDescription = useSelector(selectTestFullDescription);

  return (
    <CurrentTestPanel
      {...props}
      hasInitialInstruction={hasInitialInstruction}
      question={question}
      questionNumber={questionNumber}
      setInstruction={setInstruction}
      onBackClick={router.back}
      answer={answer}
      moveForward={moveForward}
      moveBack={moveBack}
      hasRequiredTokenScope={hasRequiredTokenScope}
      setAccessToken={setAccessTokenWithWarning}
      showFullDescription={Boolean(fullDescription && !isPassingStarted)}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    hasProgressBar: selectHasProgressBar(state),
    isDesktop: selectIsDesktop(state),
  };
}

export default connect(mapStateToProps)(CurrentTestPanelContainer);
