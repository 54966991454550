import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ResultType } from '../../types/result.type';
import { OutputHandlerType } from '../../constants/OutputHandler';

type ResultStateType = {
  data: ResultType | null;
  loading: boolean;
  error: string | null;
};

const resultInitialState: ResultStateType = {
  data: null,
  loading: false,
  error: null,
};

const resultSlice = createSlice({
  name: 'result',
  initialState: resultInitialState,
  reducers: {
    updateResult(state, action: PayloadAction<ResultType | undefined>) {
      const result = action.payload;

      if (result) {
        state.data = result;
      } else {
        state.error = 'Результаты прохождения указанного теста не найдены';
      }
    },
    updateDataByHandlerId(
      state,
      action: PayloadAction<{ output: OutputHandlerType, data: { [k: string]: unknown} }>
    ) {
      const resultData = state.data;

      if (resultData) {
        const result = resultData.result;
        const modalResult = result.find((item) => item.output === action.payload.output);
        if (modalResult) {
          modalResult.data = {
            ...(typeof modalResult.data === 'object' ? modalResult.data : {}),
            ...action.payload.data
          };
        } else {
          result.push(action.payload);
        }
      }
    },
    resetResult(state) {
      state.data = null;
    },
  },
});

export const {
  updateResult,
  resetResult,
  updateDataByHandlerId,
} = resultSlice.actions;

export default resultSlice.reducer;
