import React from 'react';

import { View } from '@vkontakte/vkui';

import { ViewType } from '../../constants/View';
import { PANEL, PanelType } from '../../constants/Panel';

import { ProfilePanel } from './panels/ProfilePanel';
import { ResultPanel } from './panels/ResultPanel';

export type ProfileViewPropsType = {
    id: ViewType;
    activePanel: PanelType;
};

export const ProfileView: React.FC<ProfileViewPropsType> = ({ id, activePanel }) => {
  return (
    <View id={id} activePanel={activePanel}>
      <ProfilePanel id={PANEL.PROFILE} />
      <ResultPanel id={PANEL.RESULT} />
    </View>
  );
};
