import { connect } from 'react-redux';

import { AppDispatch, RootState } from '../../../store';

import { resetPopout } from '../../../store/popoutSlice';
import { selectPopoutMetaInfo } from '../../../store/popoutSlice/popoutSelectors';

import { ErrorSnackbar } from './ErrorSnackbar';
import { ErrorSnackbarMetaInfo } from './types';

function mapStateToProps(state: RootState) {
  return { ...(selectPopoutMetaInfo(state) as ErrorSnackbarMetaInfo) };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    onSnackbarClose: () => dispatch(resetPopout()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorSnackbar);
