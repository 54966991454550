import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Group, Button, Div, Spacing } from '@vkontakte/vkui';

import styles from './FullDescription.module.css';

type FullDescriptionProps = {
  text?: string;
  onStart: () => void;
};

export const FullDescription: React.FC<FullDescriptionProps> = ({ text, onStart }) => {
  if (!text) {
    return null;
  }
  
  return (
    <Group>
      <Div>
        <ReactMarkdown className={styles.text}>{text}</ReactMarkdown>
        <Spacing size={12} />
        <Button
          appearance='accent'
          size='l'
          mode='primary'
          stretched
          onClick={onStart}
        >
          Вперёд
        </Button>
        <Spacing size={8} />
      </Div>
    </Group>
  );
};
