import React, { FormEvent } from 'react';
import { connect } from 'react-redux';

import store, { AppDispatch, RootState } from '../../../../store';

import {
  sendEmailAboutTest,
  updateAddingTestFormData,
} from '../../../../store/addingTestFormSlice';
import { setPopoutType, resetPopoutType } from '../../../../store/popoutSlice';

import {
  selectAddingTestFormFirstName,
  selectAddingTestFormLastName,
  selectAddingTestFormOrganization,
  selectAddingTestFormEmail,
  selectAddingTestFormText,
  selectIsFormValid,
  selectError,
  selectWasSent,
} from '../../../../store/addingTestFormSlice/addingTestFormSelectors';

import { useRouter } from '../../../../hooks/useRouter';

import { AddingTestFormType } from '../../../../types/addingTestForm.type';
import { PanelType } from '../../../../constants/Panel';

import { AddingTestFormPanel } from './AddingTestFormPanel';
import { POPOUT } from '../../../../constants/Popout';

type ContainerPropsType = AddingTestFormType & {
  id: PanelType;
  onSubmit: (event: FormEvent) => void;
  updateField: (field: string, value: string) => void;
  formError: string | null;
  wasSent: boolean;
};

const AddingTestFormPanelContainer: React.FC<ContainerPropsType> = (props) => {
  const router = useRouter();

  return (
    <AddingTestFormPanel
      {...props}
      onBackClick={router.back}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    firstName: selectAddingTestFormFirstName(state),
    lastName: selectAddingTestFormLastName(state),
    organization: selectAddingTestFormOrganization(state),
    email: selectAddingTestFormEmail(state),
    text: selectAddingTestFormText(state),
    formError: selectError(state),
    wasSent: selectWasSent(state),
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const state = store.getState();
    const isFormValid = selectIsFormValid(state);

    if (!isFormValid) {
      return;
    }

    dispatch(setPopoutType(POPOUT.SMART_SPINNER));
    await dispatch(sendEmailAboutTest());
    dispatch(resetPopoutType());
  };

  return {
    onSubmit,
    updateField: (field: string, value: string) =>
      dispatch(updateAddingTestFormData({ [field]: value })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddingTestFormPanelContainer);
