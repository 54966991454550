import React from 'react';
import { Div } from '@vkontakte/vkui';

import { SETTINGS } from '../../../constants/Settings';
import { GroupHeader } from '../../common/GroupHeader';

import { Setting } from '../Setting';

import { DecorationSettingsProps } from './types';

export const DecorationSettings: React.FC<DecorationSettingsProps> = ({
    hasProgressbar,
    hasInitialInstruction,
    changeSetting,
}) => {
    return (
        <>
            <Div style={{ paddingBottom: 0 }}>
                <GroupHeader>
                    Оформление
                </GroupHeader>
            </Div>
            <Setting
                title="Шкала прогресса"
                option={SETTINGS.HAS_PROGRESSBAR}
                value={hasProgressbar}
                onChange={changeSetting}
            />
            <Setting
                title="Показывать инструкцию в начале прохождения"
                option={SETTINGS.HAS_INITIAL_INSTRUCTION}
                value={hasInitialInstruction}
                onChange={changeSetting}
            />
        </>
    );
};
