import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '..';
import { selectAddingsTestFormData } from './addingTestFormSelectors';
import { selectUserVKID } from '../userSlice/userSelectors';

import { AddingTestFormType } from '../../types/addingTestForm.type';

import eventsApi from '../../api/EventsApi';

import { isError } from '../../utils/isError';

type AddingTestFormStateType = {
  data: AddingTestFormType;
  wasSent: boolean;
  loading: boolean;
  error: string | null;
};

export const sendEmailAboutTest = createAsyncThunk<undefined, undefined, { rejectValue: string }>(
  'addingTestForm/sendMailAboutTest',
  async function (_, { rejectWithValue, getState }) {
    try {
      const state = getState() as RootState;
      
      const addingTestFormData = selectAddingsTestFormData(state);
      const vkId = selectUserVKID(state);

      if (!vkId) {
        return;
      }

      await eventsApi.sendEmailAboutTest(vkId, addingTestFormData);
    }
    catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

const addingTestFormInitialState: AddingTestFormStateType = {
  data: {
    firstName: '',
    lastName: '',
    organization: '',
    email: '',
    text: '',
  },
  wasSent: false,
  loading: false,
  error: null,
};

const addingTestFormSlice = createSlice({
  name: 'addingTestForm',
  initialState: addingTestFormInitialState,
  reducers: {
    updateAddingTestFormData(state, action: PayloadAction<Partial<AddingTestFormType>>) {
      Object.assign(state.data, action.payload);
    },
    resetAddingTestFormData(state) {
      state.data = addingTestFormInitialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendEmailAboutTest.pending, (state) => {
        state.loading = true;
        state.wasSent = false;
        state.error = null;
      })
      .addCase(sendEmailAboutTest.fulfilled, (state) => {
        state.wasSent = true;
        state.data = addingTestFormInitialState.data;
        state.loading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export const {
  updateAddingTestFormData,
  resetAddingTestFormData,
} = addingTestFormSlice.actions;

export default addingTestFormSlice.reducer;
