import React from 'react';

import {
  Group,
  Panel,
  PanelHeader,
  Placeholder,
} from '@vkontakte/vkui';
import { Icon24UserSquareOutline } from '@vkontakte/icons';

import { PanelType } from '../../../../constants/Panel';
import { NAVIGATION } from '../../../../constants/Navigation';

import { DesktopNavigation } from '../../../../components/navigation/DesktopNavigation';

import { ErrorPlaceholder } from '../../../../components/common/ErrorPlaceholder';
import { LoadingText } from '../../../../components/common/LoadingText';
import { Profile } from '../../../../components/profile/Profile';
import { ResultList } from '../../../../components/profile/ResultList';

export type ProfilePanelPropsType = {
  id: PanelType;
  isDesktop: boolean;
  resultsLoading: boolean;
  resultsError: string | null;
};

export const ProfilePanel: React.FC<ProfilePanelPropsType> = ({ id, isDesktop, resultsLoading, resultsError }) => {
  return (
    <Panel id={id}>
      <PanelHeader>Профиль</PanelHeader>
      <Group>
        {isDesktop && <DesktopNavigation id={NAVIGATION.DESKTOP} />}
        <Profile />
        {!resultsLoading && !resultsError && (
          <ResultList />
        )}
        {resultsLoading && (
          <Placeholder
            icon={<Icon24UserSquareOutline width={56} height={56} />}
            header={<LoadingText>Загружаем результаты тестирований</LoadingText>}
          />
        )}
        {Boolean(resultsError) && (
          <ErrorPlaceholder header='При загрузке результатов произошла ошибка' />
        )}
      </Group>
    </Panel>
  );
};
