import React from 'react';

import { View } from '@vkontakte/vkui';

import { ViewType } from '../../constants/View';
import { PANEL, PanelType } from '../../constants/Panel';

import { TestsPanel } from './panels/TestsPanel';
import { CurrentTestPanel } from './panels/CurrentTestPanel';
import { AddingTestFormPanel } from './panels/AddingTestFormPanel';

export type TestsViewPropsType = {
  id: ViewType;
  activePanel: PanelType;
};

export const TestsView: React.FC<TestsViewPropsType> = ({ id, activePanel }) => {
  return (
    <View id={id} activePanel={activePanel}>
      <TestsPanel id={PANEL.TESTS} />
      <CurrentTestPanel id={PANEL.CURRENT_TEST} />
      <AddingTestFormPanel id={PANEL.ADDING_TEST_FORM} />
    </View>
  );
};
