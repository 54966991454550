import React from 'react';

import {
  Group,
  Panel,
  PanelHeader,
  Placeholder,
  Spacing,
} from '@vkontakte/vkui';

import { Icon24BillheadOutline } from '@vkontakte/icons';

import { PanelType } from '../../../../constants/Panel';
import { NAVIGATION } from '../../../../constants/Navigation';

import { Announcement } from '../../../../components/common/Announcement';
import { DesktopNavigation } from '../../../../components/navigation/DesktopNavigation';
import { TestList } from '../../../../components/test/TestList';
import { LoadingText } from '../../../../components/common/LoadingText';
import { ErrorPlaceholder } from '../../../../components/common/ErrorPlaceholder';

export type TestsPanelPropsType = {
  id: PanelType;
  loading?: boolean;
  error: string | null;
  isDesktop: boolean;
};

export const TestsPanel: React.FC<TestsPanelPropsType> = ({ id, loading, error, isDesktop }) => {

  return (
    <Panel id={id}>
      <PanelHeader>Тесты</PanelHeader>
      <Group>
        {isDesktop && <DesktopNavigation id={NAVIGATION.DESKTOP} />}
        <Announcement />
        {isDesktop && <Spacing />}
        <TestList/>
        {loading && (
          <Placeholder
            icon={<Icon24BillheadOutline width={56} height={56} />}
            header={<LoadingText>Загружаем тесты</LoadingText>}
          />
        )}
        {Boolean(error) && (
          <ErrorPlaceholder header='При загрузке тестов произошла ошибка' />
        )}
      </Group>
    </Panel>
  );
};
