import React from 'react';

import {
  Div,
  SimpleCell,
} from '@vkontakte/vkui';

import {
  Icon28Users3Outline,
  Icon28MailOutline,
} from '@vkontakte/icons';

import { GroupHeader } from '../../common/GroupHeader';

import { ContactInfoProps } from './ContactInfo.types';

export const ContactInfo: React.FC<ContactInfoProps> = ({ setModal }) => {
  return (
    <>
      <Div style={{ paddingBottom: 0 }}>
        <GroupHeader>
          Контактная информация
        </GroupHeader>
      </Div>
      <SimpleCell
        before={<Icon28Users3Outline/>}
        multiline
        onClick={setModal}
      >
        O лаборатории ТиМПИ
      </SimpleCell>
      <SimpleCell
        before={<Icon28MailOutline/>}
        multiline
        hasActive={false}
        hasHover={false}
        style={{ cursor: 'auto' }}
      >
        По вопросам размещения своих тестов пишите на почту mail@dscs.pro
      </SimpleCell>
    </>
  );
};
