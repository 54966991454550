import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { resetPopout } from '../../../store/popoutSlice';
import { selectIsDesktop } from '../../../store/settingsSlice/selectors';

import usersApi from '../../../api/UsersApi';

import { PrivacyPolicyAlertContainerProps } from './PrivacyPolicyAlert.types';
import { PrivacyPolicyAlert } from './PrivacyPolicyAlert';

const PRIVACY_POLICY_URL = 'https://vk.com/@ticslabs-politika-konfidencialnosti';

export const PrivacyPolicyAlertContainer: React.FC<PrivacyPolicyAlertContainerProps> = (props) => {
  const dispatch = useAppDispatch();

  const triggerAcceptPrivacyPolicyRequest = useCallback(async () => {
    await usersApi.acceptPrivacyPolicy();
    dispatch(resetPopout());
  }, [dispatch]);

  return (
    <PrivacyPolicyAlert
      {...props}
      triggerAcceptPrivacyPolicyRequest={triggerAcceptPrivacyPolicyRequest}
      privacyPolicyURL={PRIVACY_POLICY_URL}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    isDesktop: selectIsDesktop(state),
  };
}

export default connect(mapStateToProps)(PrivacyPolicyAlertContainer);
