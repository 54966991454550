import React, { ChangeEvent } from 'react';

import {
  FormItem,
  Textarea,
} from '@vkontakte/vkui';

import { AnswerType } from '../../../types/answer.type';

type FormAnswerType = {
  a_id: number;
  content: string;
};

type FormPropsType = {
  possibleAnswer: AnswerType;
  givenAnswers: object[];
  answer: (items: object[]) => void;
};

export const Form: React.FC<FormPropsType> = ({
  possibleAnswer: {
    a_id,
    content,
    placeholder,
  },
  givenAnswers,
  answer,
}) => {
  const getDefaultValue = (answerId: number) => (givenAnswers as FormAnswerType[])
    .find(({ a_id }) => a_id === answerId)?.content;

  const onFormChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const currentValue = event.currentTarget.value;
    const index = (givenAnswers as FormAnswerType[]).findIndex(
      (givenAnswer) => givenAnswer.a_id === a_id
    );

    const currentAnswer = {
      a_id,
      content: currentValue,
    };


    if (index !== -1) {
      answer([
        ...givenAnswers.slice(0, index),
        ...(currentValue ? [currentAnswer] : []),
        ...givenAnswers.slice(index + 1)
      ]);
    } else {
      answer([
        ...givenAnswers,
        currentAnswer
      ]);
    }
  };

  return (
    <FormItem top={content}>
      <Textarea
        rows={1}
        defaultValue={getDefaultValue(a_id)}
        placeholder={placeholder}
        name={`form-${a_id}`}
        onChange={onFormChange}
      />
    </FormItem>
  );
};
