import React from 'react';

import {
  Header,
  Link,
  Title,
} from '@vkontakte/vkui';
import { Icon24InfoCircleOutline } from '@vkontakte/icons';

import { TestLabelProps } from './TestLabel.types';

export const TestLabel: React.FC<TestLabelProps> = ({ label, onInfoClick }) => {
  if (!label) {
    return null;
  }

  return (
    <Header
      aside={(
        <Link onClick={onInfoClick}>
          <Icon24InfoCircleOutline />
        </Link>
      )}
      multiline
    >
      <Title
        level='2'
        style={{ fontSize: '18px' }}
      >
        {label}
      </Title>
    </Header>
  );
};
