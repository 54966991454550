import api from './api';

import { serializeToQueryParams } from '../utils/serializeToQueryParams';
import { TestType } from '../types/test.type';

export type GetTestsQueryParams = {
  testsShortName?: string[];
  withQuestions?: boolean;
  withPercent?: boolean;
};

function getTests(params: GetTestsQueryParams = {}): Promise<TestType[]> {
  const {
    testsShortName,
    withQuestions,
    withPercent,
  } = Object.assign({ withQuestions: false, withPercent: false }, params);

  const queryParams = serializeToQueryParams({
    test_short_name: testsShortName?.join(','),
    with_questions: Number(withQuestions),
    with_percent: Number(withPercent),
  });

  return api(`tests/${queryParams && ('?' + queryParams)}`);
}

function getTest(shortName: string): Promise<TestType> {
  return api(`tests/${shortName}`);
}

const testsApi = { getTests, getTest };

export default testsApi;
