import React from 'react';

import {
  Button,
  ButtonGroup,
  Div,
} from '@vkontakte/vkui';

import { QuestionHandlerPropsType } from '../../test/Answer/Answer';

import styles from './ButtonHandler.module.css';

type ButtonAnswerType = {
  a_id: number;
}

export const ButtonHandler: React.FC<QuestionHandlerPropsType> = ({
  possibleAnswers,
  answer,
  moveForward,
}) => {
  const onButtonClick = async (currentAnswer: ButtonAnswerType) => {
    answer([currentAnswer]);
    await moveForward();
  };

  return (
    <Div>
      <ButtonGroup
        mode='vertical'
        stretched
        gap='s'
      >
        {possibleAnswers.map(({ a_id, content }) => (
          <Button
            key={a_id}
            appearance='accent'
            size='m'
            mode='secondary'
            stretched
            onClick={() => onButtonClick({ a_id })}
          >
            <div className={styles.buttonContent}>
              {content}
            </div>
          </Button>
        ))}
      </ButtonGroup>
    </Div>
  );
};
