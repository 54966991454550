import api from './api';

import { ResultType } from '../types/result.type';

import { serializeToQueryParams } from '../utils/serializeToQueryParams';

export type GetResultsQueryParams = {
  testsShortName?: string[];
  isLast?: boolean;
  isProcessed?: boolean;
};

function getResults(params: GetResultsQueryParams = {}): Promise<ResultType[]> {
  const {
    testsShortName,
    isLast,
    isProcessed
  } = Object.assign({ isLast: true, isProcessed: false }, params);

  const queryParams = serializeToQueryParams({
    test_short_name: testsShortName?.join(','),
    is_last: Number(isLast),
    is_processed: Number(isProcessed),
  });

  return api(`results${queryParams && ('?' + queryParams)}`);
}

function formResult(testShortName: string) {
  return api(`results/form_result?test_short_name=${testShortName}`);
}

const resultsApi = { getResults, formResult };

export default resultsApi;
