import api from './api';

import { StatusType } from '../types/passing.type';
import { UserAnswerType, StatesType } from '../types/userAnswer.type';

import { serializeToQueryParams } from '../utils/serializeToQueryParams';

const PASSINGS = 'passings';

export type GetPassingsQueryParams = {
  testsShortName?: string[];
  status?: StatusType;
  states?: StatesType;
};

function getPassings({ testsShortName, status, states }: GetPassingsQueryParams = {}) {
  const queryParams = serializeToQueryParams({
    test_short_name: testsShortName?.join(','),
    status,
    states,
  });

  return api(`${PASSINGS}${queryParams && ('?' + queryParams)}`);
}

async function getPassing(testShortName: string) {
  const [data] = await api(`${PASSINGS}?test_short_name=${testShortName}&status=in_progress&states=accepted,skipped`);
  
  if (data) {
    const { vk_id, _id, __v, ...passing } = data;
    return passing;
  }
}

function saveAnswer(userAnswer: UserAnswerType & { test_short_name: string }) {
  return api(`${PASSINGS}/save_answer`, userAnswer);
}

type SkipQuestionsRequest = {
  questionsIds: number[];
  testShortName: string;
};

async function skipQuestions({ questionsIds, testShortName }: SkipQuestionsRequest) {
  return api(`${PASSINGS}/skip_questions`, { q_ids: questionsIds, test_short_name: testShortName });
}

async function startPassing(testShortName: string) {
  return api(PASSINGS, { test_short_name: testShortName });
}

const passingsApi = {
  getPassings,
  getPassing,
  saveAnswer,
  skipQuestions,
  startPassing,
};

export default passingsApi;
