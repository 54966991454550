import React from 'react';

import {
  Button,
  Card,
  Text,
  PopoutWrapper,
  Link,
  Title,
  Spacing,
} from '@vkontakte/vkui';

import styles from './PrivacyPolicyAlert.module.css';
import './overrideVkui.css';

import { PrivacyPolicyAlertProps } from './PrivacyPolicyAlert.types';

const SPACE = ' ';

export const PrivacyPolicyAlert: React.FC<PrivacyPolicyAlertProps> = ({
  isDesktop,
  triggerAcceptPrivacyPolicyRequest,
  privacyPolicyURL
}) => {
  const renderLink = () => {
    return (
      <Link href={privacyPolicyURL} target='_blank'>
        политику конфиденциальности
      </Link>
    );
  };

  return (
    // Класс PrivacyPolicyAlert__PopoutWrapper добавлен для переопределения
    // стилей PopoutWrapper только на уровне компонента PrivacyPolicyAlert
    <PopoutWrapper
      alignY='center'
      alignX='center'
      className='PrivacyPolicyAlert__PopoutWrapper'
    >
      <Card mode='shadow' className={styles.content}>
        <Title weight='2' level='2' className={styles.header}>
            Политика конфиденциальности
        </Title>
        <div className={styles.textContainer}>
          <Text weight='3' className={styles.text}>
            Для продолжения использования приложения
            {!isDesktop && SPACE}
            {isDesktop && <Spacing size={0} />}
            примите, пожалуйста, {renderLink()}.
          </Text>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={triggerAcceptPrivacyPolicyRequest}
            size='m'
            className={styles.button}
          >
              Принять
          </Button>
        </div>
      </Card>
    </PopoutWrapper>
  );
};
