import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LaunchParams } from '../../types/launchparams.type';

import { LAUNCH_PARAMS_INITIAL_STATE } from './constants';

const launchParamsSlice = createSlice({
    name: 'launchParams',
    initialState: LAUNCH_PARAMS_INITIAL_STATE,
    reducers: {
        updateLaunchParams(state, action: PayloadAction<Partial<LaunchParams>>) {
            state.data = {
                ...state.data,
                ...action.payload
            };
        }
    }
});

export const { updateLaunchParams } = launchParamsSlice.actions;

export default launchParamsSlice.reducer;
