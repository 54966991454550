import React from 'react';

import { OUTPUT_HANDLER, OutputHandlerType } from '../../../constants/OutputHandler';

import outputHandlerMap from '../../outputHandlers';

export type ResultPropsType = {
  output: OutputHandlerType;
  [key: string]: unknown;
};

export const Result: React.FC<ResultPropsType> = ({
  output,
  ...props
}) => {
  if (output === OUTPUT_HANDLER.MODAL) {
    return null;
  }
  const Handler = outputHandlerMap[output];

  // @ts-ignore
  return React.createElement(Handler, props);
};
