import React, { ReactNode } from 'react';
import { Title } from '@vkontakte/vkui';

export type GroupHeaderPropsType = {
  children: ReactNode;
};

export const GroupHeader: React.FC<GroupHeaderPropsType> = ({ children }) => {
  return (
    <Title style={{
      fontSize: 13,
      textTransform: 'uppercase',
      color: 'var(--vkui--color_text_secondary)',
    }}>
      {children}
    </Title>
  );
};
