import { Title, Text, Button } from '@vkontakte/vkui';
import React from 'react';

import { useNotifications } from '../hooks';

import styles from './index.module.css';

export const Banner: React.FC = () => {
    const { enableNotifications, disableNotifications } = useNotifications();

    return (
        <div className={styles.banner}>
            <div className={styles.content}>
                <Title level="2">Разрешение на уведомления</Title>
                <Text>Обещаем, что будем присылать только самые важные оповещения - о новых тестах и больших обновлениях :)</Text>
            </div>
            <div className={styles.actions}>
                <Button
                    size="l"
                    mode="primary"
                    appearance="overlay"
                    onClick={enableNotifications}
                >
                    Разрешить уведомления
                </Button>
                <Button
                    size="l"
                    mode="outline"
                    appearance="overlay"
                    onClick={disableNotifications}
                >
                    Запретить
                </Button>
            </div>
        </div>
    );
};
