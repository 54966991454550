import { POPOUT } from '../../constants/Popout';

import { SmartSpinner } from './SmartSpinner';
import { ErrorSnackbar } from './ErrorSnackbar';
import { TokenScopeAlert } from './TokenScopeAlert';
import { PrivacyPolicyAlert } from './PrivacyPolicyAlert';

const popoutMap = {
  [POPOUT.SMART_SPINNER]: SmartSpinner,
  [POPOUT.ERROR_SNACKBAR]: ErrorSnackbar,
  [POPOUT.TOKEN_SCOPE_ALERT]: TokenScopeAlert,
  [POPOUT.PRIVACY_POLICY_ALERT]: PrivacyPolicyAlert,
};

export default popoutMap;
