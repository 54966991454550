import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { PercentInfo } from '../../types/test.type';

export const selectTests = (state: RootState) => state.tests.data;

export const selectTestByShortName = (state: RootState, shortName: string) => {
  return selectTests(state).find(({ short_name }) => short_name === shortName);
};

export const selectFullNameByShortName = (state: RootState, shortName: string) => {
  return selectTestByShortName(state, shortName)?.full_name;
};

export const selectTokenScopeByShortName = createSelector(
  selectTestByShortName,
  (test) => test?.options.token_scope
);

export const selectLoading = (state: RootState) => state.tests.loading;

export const selectError = (state: RootState) => state.tests.error;

export const selectHasSkippableQuestions = createSelector(
  selectTestByShortName,
  (test) => {
    if (!test || !test.questions) {
      return false;
    }

    return test.questions.some((question) =>
      question.display_conditions &&
      question.display_conditions.length !== 0
    );
  },
);

export const selectPercentInfoByTestShortName = createSelector(
  selectTestByShortName,
  (test) => {
    if (!test || !test.additional_info) {
      return;
    }

    const answeredQuestionsCount =
      test.additional_info.answered_questions_count ||
      test.questions?.filter(({ state }) => ['accepted', 'skipped'].includes(state)).length || 0;

    const questionsCount = test.additional_info.questions_count;

    const percentFormatter = Intl.NumberFormat('en-US', {
      style: 'percent',
      maximumFractionDigits: 0
    });

    const percent = answeredQuestionsCount / questionsCount;
    const formattedPercent = percentFormatter.format(percent);

    const percentInfo: PercentInfo = {
      answeredQuestionsCount,
      questionsCount,
      percent,
      formattedPercent,
    };

    return percentInfo;
  }
);
