import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Radio, RadioGroup } from '@vkontakte/vkui';

import { QuestionHandlerPropsType } from '../../test/Answer/Answer';

type RadioAnswerType = {
  a_id: number;
};

export const RadioHandler: React.FC<QuestionHandlerPropsType> = ({
  answer,
  possibleAnswers,
  givenAnswers,
}) => {
  const onRadioChange = (currentAnswer: RadioAnswerType) => {
    answer([currentAnswer]);
  };

  const isChecked = (answerId: number) => {
    return Boolean((givenAnswers as RadioAnswerType[]).filter(({ a_id }) => a_id === answerId).length);
  };

  return (
    <RadioGroup mode='vertical'>
      {possibleAnswers.map(({ a_id, content, description }) => (
        <Radio
          key={a_id}
          description={description}
          name='radio-answer'
          value={`radio-answer-${a_id}`}
          checked={isChecked(a_id)}
          onChange={() => onRadioChange({ a_id })}
        >
          <ReactMarkdown
            unwrapDisallowed={true}
            disallowedElements={['p']}
          >
            {content || ''}
          </ReactMarkdown>
        </Radio>
      ))}
    </RadioGroup>
  );
};