import React from 'react';

import {
  Div,
  CellButton,
} from '@vkontakte/vkui';
import { Icon28ReplyOutline } from '@vkontakte/icons';

import { GroupHeader } from '../../common/GroupHeader';

export type AppSharePropsType = {
  shareApp: () => void;
};

export const AppShare: React.FC<AppSharePropsType> = ({ shareApp }) => {
  return (
    <>
      <Div style={{ paddingBottom: 0 }}>
        <GroupHeader>
          Рассказать о нас
        </GroupHeader>
      </Div>
      <CellButton 
        multiline 
        before={(
          <Icon28ReplyOutline
            style={{
              transform: 'rotateY(180deg)',
              padding: '10px 0px 10px 16px',
            }}
          />
        )}
        onClick={shareApp}
      >
        Поделиться приложением на странице
      </CellButton>
    </>
  );
};
