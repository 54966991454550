import React from 'react';

import { Spacing, Text } from '@vkontakte/vkui';

import { ParagraphsProps } from './types';

export const Paragraphs: React.FC<ParagraphsProps> = ({
  text,
  paragraphClassName,
  spacing,
  textComponent: TextComponent = Text
}) => {
  const paragraphsTexts = text.split('\n');
  return (
    <div>
      {
        paragraphsTexts.map((text, index) => (
          <>
            <TextComponent className={paragraphClassName}>
              {text}
            </TextComponent>
            {
              index !== paragraphsTexts.length - 1 && <Spacing size={spacing}/>
            }
          </>
        ))
      }
    </div>
  );
};
