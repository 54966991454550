import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PopoutType } from '../../constants/Popout';

type PopoutStateType = {
  type: PopoutType | null;
  meta_info: {
    [key: string]: any;
  },
};

const popoutInitialState: PopoutStateType = {
  type: null,
  meta_info: {},
};

const popoutSlice = createSlice({
  name: 'popout',
  initialState: popoutInitialState,
  reducers: {
    setPopout(state, action: PayloadAction<PopoutStateType>) {
      Object.assign(state, action.payload);
    },
    resetPopout(state) {
      Object.assign(state, popoutInitialState);
    },
    setPopoutType(state, action: PayloadAction<PopoutType>) {
      state.type = action.payload;
    },
    resetPopoutType(state) {
      state.type = null;
    },
  },
});

export const {
  setPopout,
  resetPopout,
  setPopoutType,
  resetPopoutType,
} = popoutSlice.actions;

export default popoutSlice.reducer;
