import React from 'react';

import { Card, classNames } from '@vkontakte/vkui';

import styles from './CardForScroll.module.css';
import { CardForScrollProps } from './types';

export const CardForScroll: React.FC<CardForScrollProps> = ({
  children,
  isFirst,
  isLast
}) => {
  return (
    <Card
      mode="shadow"
      className={classNames(
        styles.card,
        isFirst && styles.first,
        isLast && styles.last,
      )}>
      {children}
    </Card>
  );
};
