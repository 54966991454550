import React from 'react';

import {
  FixedLayout,
  Div,
  SubnavigationBar,
  SubnavigationButton,
  Button,
} from '@vkontakte/vkui';

import {
  Icon28ChevronLeftOutline,
  Icon28ChevronRightOutline,
} from '@vkontakte/icons';

import { TestNavigationProps } from './TestNavigation.types';

export const TestNavigation: React.FC<TestNavigationProps> = ({
  isFirstQuestion,
  canRedo = false,
  moveForward,
  moveBack,
}) => {
  const layoutStyle = {
    paddingBottom: '0.4em',
  };

  const navigationStyle = {
    boxShadow: '0 0 8px rgba(0, 0, 0, .08), 0 2px 24px rgba(0, 0, 0, .08)',
  };

  return (
    <FixedLayout vertical='bottom' style={layoutStyle}>
      {canRedo ? (
        <SubnavigationBar
          mode='fixed'
          data-testid='navigation-with-redo'
        >
          <SubnavigationButton
            before={<Icon28ChevronLeftOutline width={36} height={36} />}
            size='l'
            disabled={isFirstQuestion}
            onClick={moveBack}
            style={navigationStyle}
            data-testid='navigation-back'
          />
          <SubnavigationButton
            before={<Icon28ChevronRightOutline width={36} height={36} />}
            size='l'
            onClick={moveForward}
            style={navigationStyle}
            data-testid='navigation-forward'
          />
        </SubnavigationBar>
      ) : (
        <Div data-testid='navigation-without-redo'>
          <Button
            stretched
            size='l'
            hasActive={false}
            onClick={moveForward}
          >
            Ответить
          </Button>
        </Div>
      )}
    </FixedLayout>
  );
};
