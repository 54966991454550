import React from 'react';

import { Div, Title } from '@vkontakte/vkui';

import questionHandlerMap from '../../questionHandlers';

import { AnswerType } from '../../../types/answer.type';
import { QuestionMetaInfoType } from '../../../types/question.type';
import { QuestionHandlerType } from '../../../constants/QuestionHandler';

export type QuestionHandlerPropsType = {
  possibleAnswers: AnswerType[];
  givenAnswers: object[];
  metaInfo?: QuestionMetaInfoType;
  answer: (items: object[]) => void;
  moveForward: () => Promise<void>;
  moveBack: () => void;
};

type AnswerPropsType = QuestionHandlerPropsType & {
  handler: QuestionHandlerType;
};

export const Answer: React.FC<AnswerPropsType> = ({
  handler,
  ...props
}) => {
  const Handler = questionHandlerMap[handler];

  return (
    <>
      <Div>
        <Title level='3'>Варианты ответа</Title>
      </Div>
      {React.createElement(Handler, props)}
    </>
  );
};
