import React from 'react';

import {
  ContentCard,
  Text,
} from '@vkontakte/vkui';

export type ResultCardPropsType = {
  setResult: () => void;
  testFullName?: string;
  completionDate: string;
};

export const ResultCard: React.FC<ResultCardPropsType> = ({
  setResult,
  testFullName,
  completionDate,
}) => {
  const renderFullName = () => {
    if (!testFullName) {
      return;
    }

    return (
      <Text style={{ fontSize: 20, marginBottom: 8, lineHeight: 1.1 }}>
        {testFullName}
      </Text>
    );
  };

  const renderCompletionDate = () => {
    if (!completionDate) {
      return;
    }

    return `Тест был пройден в ${completionDate}`;
  };
  
  return (
    <ContentCard
      onClick={setResult}
      maxHeight={120}
      mode='tint'
      text={renderFullName()}
      caption={renderCompletionDate()}
    />
  );
};