import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import bridge from '@vkontakte/vk-bridge';

import { useAppDispatch } from './useAppDispatch';

import { selectAppId } from '../store/launchParamsSlice/selectors';
import { selectTestTokenScope } from '../store/testSlice/testSelectors';
import { selectUserGrantedTokenScope } from '../store/userSlice/userSelectors';

import { updateUser } from '../store/userSlice';

import { mergeArrays } from '../utils/mergeArrays';

import { RootState } from '../store';
import { selectTokenScopeByShortName } from '../store/testsSlice/testsSelectors';

export const useSetAccessToken = (testShortName: string = '') => {
  const dispatch = useAppDispatch();

  const appId = useSelector(selectAppId);
  const grantedTokenScope = useSelector(selectUserGrantedTokenScope);
  const requiredTokenScopeFromTest = useSelector(selectTestTokenScope);
  const requiredTokenScopeFromParams = useSelector(
    (state: RootState) => selectTokenScopeByShortName(state, testShortName)
  );
  const requiredTokenScope = testShortName ? requiredTokenScopeFromParams : requiredTokenScopeFromTest;

  return useCallback(async () => {
    if (!appId || !requiredTokenScope) {
      return;
    }

    try {
      const { access_token, scope } = await bridge.send('VKWebAppGetAuthToken', {
        app_id: appId,
        scope: requiredTokenScope.join(),
      });

      dispatch(updateUser({
        access_token,
        granted_token_scope: mergeArrays(grantedTokenScope || [], scope.split(',') || []),
      }));
    } catch {
      throw new Error('REQUEST_ACCESS_TOKEN_REJECTED');
    }
  }, [appId, grantedTokenScope, requiredTokenScope, dispatch]);
};
