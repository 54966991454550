import React from 'react';

import { useRouter } from '../../../hooks/useRouter';

import { MODAL } from '../../../constants/Modal';

import { ContactInfo } from './ContactInfo';

const ContactInfoContainer: React.FC = () => {
  const router = useRouter();
  const setModal = () => router.forward({ modal: MODAL.ABOUT_US });

  return (
    <ContactInfo setModal={setModal} />
  );
};

export default ContactInfoContainer;
