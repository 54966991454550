import React from 'react';

import { Button, ButtonGroup, ModalPage, Text, Title } from '@vkontakte/vkui';

import styles from '../commonModal.module.css';
import { ShareGollandResultModalProps } from './types';

export const ShareGollandResultModal: React.FC<ShareGollandResultModalProps> = ({
  id,
  shareResulOnWall,
  shareResulInStory
}) => {
  return (
    <ModalPage id={id}>
      <div className={styles.content}>
        <Title weight='2' level='2' className={styles.header}>
          Поделиться результатом
        </Title>
        <div className={styles.textContainer}>
          <Text weight='3' className={styles.text}>
            Адаптировать результаты не нужно — мы уже собрали их в пост для вас!
          </Text>
        </div>
        <ButtonGroup mode="vertical" align="center" stretched>
          <Button
            onClick={shareResulOnWall}
            size='m'
            mode="primary"
          >
            Поделиться на стене
          </Button>
          <Button
            onClick={shareResulInStory}
            size='m'
            mode="secondary"
          >
            Поделиться в сторис
          </Button>
        </ButtonGroup>
      </div>
    </ModalPage>
  );
};
