import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

export const selectSettings = (state: RootState) => state.settings.data;

export const selectAppearance = createSelector(
  selectSettings,
  (settings) => settings.appearance,
);

export const selectPlatform = createSelector(
  selectSettings,
  (settings) => settings.platform,
);

export const selectIsDesktop = createSelector(
  selectSettings,
  (settings) => settings.isDesktop,
);

export const selectHasHeader = createSelector(
  selectSettings,
  (settings) => settings.hasHeader,
);

export const selectHasProgressBar = createSelector(
  selectSettings,
  (settings) => settings.has_progressbar,
);

export const selectHasInitialInstruction = createSelector(
  selectSettings,
  (settings) => settings.has_initial_instruction,
);

export const selectAreNotificationsEnabled = createSelector(
  selectSettings,
  (settings) => settings.are_notifications_enabled
);
