import React from 'react';
import { useEffect, useCallback, useState, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from './useAppDispatch';

import { setPopoutType, resetPopoutType } from '../store/popoutSlice';
import { selectPopoutType } from '../store/popoutSlice/popoutSelectors';

import { PopoutType } from '../constants/Popout';

import popoutMap from '../components/popouts';

export const usePopout = () => {
  const dispatch = useAppDispatch();

  const [popout, setPopout] = useState<ReactNode>(null);
  const popoutType = useSelector(selectPopoutType);

  useEffect(() => {
    if (!popoutType) {
      setPopout(null);
      return;
    }

    const PopoutComponent = React.createElement(popoutMap[popoutType]);
    setPopout(PopoutComponent);
  }, [popoutType]);

  const set = useCallback((type: PopoutType) => dispatch(setPopoutType(type)), [dispatch]);
  const reset = useCallback(() => dispatch(resetPopoutType()), [dispatch]);

  return {
    popout,
    setPopout: set,
    resetPopout: reset,
  };
};