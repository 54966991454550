import React from 'react';

import {
  Button, ButtonGroup,
  ModalPage, Text, Title,
} from '@vkontakte/vkui';

import styles from '../commonModal.module.css';
import { AboutGollandTestModalProps } from './types';
import { TEXT } from './constants';

export const AboutGollandTestModal: React.FC<AboutGollandTestModalProps> = ({
  id,
  onBackClick,
  isDesktop,
}) => {
  return (
    <ModalPage id={id}>
      <div className={styles.content}>
        <Title weight='2' level='2' className={styles.header} style={{ textAlign: isDesktop ? 'center' : 'left' }}>
          О тесте
        </Title>
        <div className={styles.textContainer}>
          <Text weight='3' className={styles.text} style={{ textAlign: isDesktop ? 'center' : 'left' }}>
            {TEXT}
          </Text>
        </div>
        <ButtonGroup mode="vertical" align={isDesktop ? 'center' : 'right'} stretched>
          <Button
            onClick={onBackClick}
            size='m'
            mode={isDesktop ? 'primary' : 'tertiary'}
          >
            Закрыть
          </Button>
        </ButtonGroup>
      </div>
    </ModalPage>
  );
};
