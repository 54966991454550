import React, { useEffect, useMemo, useState } from 'react';

import {
  AdaptivityProvider,
  Button,
  ButtonGroup,
  classNames,
  Div,
  SizeType,
  Spacing,
  Text,
  Title
} from '@vkontakte/vkui';
import { Icon12Add, Icon20InfoCircleOutline, Icon24ExternalLinkOutline } from '@vkontakte/icons';

import { BarChart } from '../../common/charts';

import styles from './GollandHandler.module.css';
import { Paragraphs } from './components/Paragraphs';
import { Features } from './components/Features';
import { OtherFactors } from './components/OtherFactors';
import { GollandHandlerProps } from './types';
import { LoadingPlaceholder } from './components/LoadingPlaceholder';
import { LightButton } from './components/LightButton';

export const GollandHandler: React.FC<GollandHandlerProps> = ({
  factors,
  factorToShow,
  imageSrc,
  isDesktop,
  shareResult,
  showInfoAboutTest,
  needRegeneration,
  onGenerateButtonClick,
  showGenerationButton,
}) => {
  const dataForChart = useMemo(() => factors.map(
    ({ name, score }) => ({ key: name, score })
  ), [factors]);
  const slideWidth = useMemo(() => isDesktop ? '90%' : window.innerWidth * 0.88, [isDesktop]);
  const imageAspectRatio = useMemo(() => isDesktop ? 1.15 : 1, [isDesktop]);
  const [showEmptyLoader, setShowEmptyLoader] = useState(true);

  useEffect(
    () => {
      let timer = setTimeout(() => setShowEmptyLoader(false),  200);
      return () => {
        clearTimeout(timer);
      };
    }, []);

  const image = (
    imageSrc ? (
      <div
        className={styles.image}
        style={{
          backgroundImage: `url('${imageSrc}')`,
          aspectRatio: imageAspectRatio
        }}
      >
        {
          showGenerationButton && (
            <ButtonGroup className={styles.regenerateButton} stretched>
              <LightButton
                onClick={onGenerateButtonClick}
                before={<Icon12Add />}
                stretched
                text={needRegeneration ? 'Сгенерировать еще раз' : 'Сгенерировать картинку'}
              />
            </ButtonGroup>
          )
        }
      </div>
    ) : (
      <LoadingPlaceholder imageAspectRatio={imageAspectRatio} showContent={!showEmptyLoader} />
    )
  );
  const chart = (
    <BarChart
      gap={isDesktop ? 'auto' : 7}
      data={dataForChart}
      formatKey={str => str.split(' ')[0]}
      minBarWidth={165}
    />
  );

  return (
    <Div>
      <Spacing size={6} />
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <Title level="2" className={styles.title}>
            {factorToShow.name}
          </Title>
          <Icon20InfoCircleOutline
            color='var(--vkui--color_text_secondary)'
            className={styles.hintIcon}
            onClick={showInfoAboutTest}
          />
        </div>

        {
          isDesktop ? (
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
              {image}
              {chart}
            </div>
          ) : (
            <>
              {image}
              {chart}
            </>
          )
        }

        {
          imageSrc && (
            <AdaptivityProvider sizeY={SizeType.REGULAR}>
              <Button stretched before={<Icon24ExternalLinkOutline />} size="l" onClick={shareResult}>
                          Поделиться результатом
              </Button>
            </AdaptivityProvider>
          )
        }

        <Paragraphs
          textComponent={Text}
          text={`Вы — ${factorToShow.name.toLowerCase()}. ${factorToShow.short_description}`}
          spacing={12}
          paragraphClassName={classNames(
            styles.paragraphCommon,
            isDesktop ? styles.paragraphDesktop : styles.paragraphMobile
          )}
        />

        <Features features={factorToShow.features} slideWidth={slideWidth} isDesktop={isDesktop} />

        <OtherFactors
          isDesktop={isDesktop}
          otherFactors={factors.filter(factor => factor.name !== factorToShow.name)}
          slideWidth={slideWidth}
        />
      </div>
    </Div>
  );
};
