const FAILED_TO_FETCH = 'Failed to fetch';
const UNEXPECTED_ERROR = 'Непредвиденная ошибка';
const PLEASE_TRY_AGAIN = 'Пожалуйста, попробуйте снова';

export const prepareError = (error: unknown) => {
  const isError = error instanceof Error;
  
  if (!isError) {
    return error;
  }

  switch(error.message) {
    case FAILED_TO_FETCH:
      return new Error(`${UNEXPECTED_ERROR}. ${PLEASE_TRY_AGAIN}!`);
    default:
      return error;
  }
};
