import React from 'react';

import { classNames, Text, Title } from '@vkontakte/vkui';
import { ThreeCircles } from  'react-loader-spinner';

import styles from './LoadingPlaceholder.module.css';
import { LoadingPlaceholderProps } from './types';

export const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({ imageAspectRatio, showContent }) => {
  if (!showContent) {
    return (
      <div
        className={styles.container}
        style={{ aspectRatio: imageAspectRatio }}
      />
    );
  }

  return (
    <div
      className={classNames(
        styles.container,
        styles.withImage,
      )}
      style={{ aspectRatio: imageAspectRatio }}
    >
      <div className={styles.titleContainer}>
        <Title level="3" className={styles.title}>
                Генерируем изображение
        </Title>
      </div>
      <div className={styles.secondaryTextContainer}>
        <Text className={styles.secondaryText}>
                Занимает в среднем 20 секунд
        </Text>
      </div>

      <ThreeCircles
        wrapperClass={styles.loaderContainer}
        height="100%"
        width="100%"
        visible
        outerCircleColor="#09DFEE"
        innerCircleColor="#b1edf2"
        middleCircleColor="#e8fefd"
      />
    </div>
  );
};
