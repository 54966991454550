import React from 'react';

import {
  Avatar,
  Div,
  Button,
  CellButton,
  List,
  Spacing,
  Cell,
  calcInitialsAvatarColor,
} from '@vkontakte/vkui';
import { Icon24Forward } from '@vkontakte/icons';

import { QuestionHandlerPropsType } from '../../test/Answer/Answer';

import { getInitialLetters } from '../../../utils/getInitialLetters';
import { convertToSex } from '../../../utils/convertToSex';

import bridge from '@vkontakte/vk-bridge';

type FriendAnswerType = {
  friend_full_name: string;
  friend_id: string | number;
  position: number;
  photo?: string;
  sex?: number | 'female' | 'male' | 'none';
  handler_info: any;
};

type FriendHandlerPropsType = QuestionHandlerPropsType & {
  getMutualFriends: (targetUids: number | number[]) => Promise<any>;
};

export const FriendHandler: React.FC<FriendHandlerPropsType> = ({
  possibleAnswers,
  givenAnswers,
  answer,
  metaInfo,
  moveForward,
  getMutualFriends,
}) => {
  const removeFromList = (index: number) => {
    const _list = [...givenAnswers];

    _list.splice(index, 1);
    answer(_list.map((item, index) => ({ ...item, position: index })));
  };
    
  const reorderList = ({ from, to }: { from: number, to: number }) => {
    const fromIndex = from - 1;
    const toIndex = to - 1;

    const _list = [...givenAnswers];

    _list.splice(fromIndex, 1);
    _list.splice(toIndex, 0, givenAnswers[fromIndex]);

    answer(_list.map((item, index) => ({ ...item, position: index })));
  };

  const addFriend = () => {
    bridge
      .send('VKWebAppGetFriends', { multi: true })
      .then(async ({ users }) => {
        const friendAnswers: FriendAnswerType[] = []; 
        
        for (const user of users.reverse()) {
          const { id, first_name, last_name, sex, photo_200 } = user;
          const isFriendSelected = (givenAnswers as FriendAnswerType[]).findIndex(
            ({ friend_id }) => friend_id === id
          ) !== -1;
          
          if (!isFriendSelected) {
            let handler_info: any;
            if (metaInfo?.vk_api && metaInfo.vk_api === 'friends.getMutual') {
              try {
                handler_info = await getMutualFriends(id);
              }
              catch (error) {
                console.log(error);
              }
            }

            friendAnswers.push({
              friend_full_name: `${first_name} ${last_name}`,
              friend_id: id,
              photo: photo_200,
              sex: convertToSex(sex),
              position: givenAnswers.length + friendAnswers.length,
              handler_info,
            });
          }
        }

        answer([
          ...givenAnswers.filter((givenAnswer: { a_id?: number }) => !givenAnswer.a_id),
          ...friendAnswers,
        ]);
      });
  };

  const onButtonClick = async (currentAnswer: { a_id: number }) => {
    answer([currentAnswer]);
    await moveForward();
  };

  const showGivenAnswers = Boolean(givenAnswers.length) && 
    !(givenAnswers[0] as { a_id: number }).a_id &&
    (givenAnswers[0] as { content: string }).content !== 'question_skipped';

  return (
    <Div>
      <Button
        mode='secondary'
        stretched
        size='l'
        onClick={addFriend}
      >
        {possibleAnswers[0].content}
      </Button>
      {showGivenAnswers && (
        <List>
          <Spacing size={12} />
          {(givenAnswers as FriendAnswerType[]).map(({
            friend_full_name,
            friend_id,
            photo,
          }, index) => (
            <Cell
              key={friend_id}
              before={(
                <Avatar
                  src={photo}
                  initials={getInitialLetters(friend_full_name)}
                  gradientColor={calcInitialsAvatarColor(Number(friend_id))}
                />
              )}
              mode='removable'
              draggable
              multiline
              onRemove={() => removeFromList(index)}
              onDragFinish={reorderList}
            >
              {friend_full_name}
            </Cell>
          ))}
        </List>
      )}
      {metaInfo?.with_button && Boolean(possibleAnswers[1]) && (
        <>
          <Spacing />
          <CellButton
            centered
            before={<Icon24Forward />}
            onClick={() => onButtonClick({ a_id: possibleAnswers[1].a_id })}
          >
            {possibleAnswers[1].content}
          </CellButton>
        </>
      )}
    </Div>
  );
};
