import React from 'react';
import { Cell, Switch } from '@vkontakte/vkui';

import { SettingProps } from './types';

export const Setting: React.FC<SettingProps> = ({ title, option, value, onChange }) => {
    return (
        <Cell
            disabled
            multiline
            after={(
                <Switch 
                    defaultChecked={value} 
                    onClick={() => onChange(option, !value)} 
                />
            )}
        >
            {title}
        </Cell>
    );
};
