import React from 'react';
import { Headline } from '@vkontakte/vkui';

import { FeatureListContent, FeatureCellContent } from './components';
import styles from './FeatureContent.module.css';
import { FeatureContentProps } from './types';

export const FeatureContent: React.FC<FeatureContentProps> = ({ feature, needTwoListColumns }) => {
  return (
    <div className={styles.container}>
      <Headline
        weight="2"
        className={styles.title}
        style={{ marginBottom: feature.type === 'cell' ? 12 : 20 }}
      >
        {feature.name}
      </Headline>
      {
        feature.type === 'cell' ? (
          <FeatureCellContent feature={feature}/>
        ) : (
          <FeatureListContent feature={feature} needTwoListColumns={needTwoListColumns} />
        )
      }
    </div>
  );
};
