import React from 'react';

import { Alert } from '@vkontakte/vkui';
import { DEFAULT_DESCRIPTION } from './constants';
import { TokenScopeAlertPropsType } from './types';

export const TokenScopeAlert: React.FC<TokenScopeAlertPropsType> = ({
  triggerAccessTokenRequest,
  description,
}) => {
  return (
    <Alert
      header='Необходимы дополнительные разрешения'
      text={(
        <span style={{ fontSize: 16, lineHeight: 'var(--vkui--font_text--line_height--regular)' }}>
          {description ? description : DEFAULT_DESCRIPTION}
        </span>
      )}
      onClose={triggerAccessTokenRequest}
      actions={[{
        title: 'Понятно',
        mode: 'default',
        action: triggerAccessTokenRequest
      }]}
    />
  );
};
