import bridge from '@vkontakte/vk-bridge';
import { useCallback } from 'react';

import { VIEW } from '../../constants/View';
import { PANEL } from '../../constants/Panel';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useRouter } from '../../hooks/useRouter';
import { saveUserSettings } from '../../store/settingsSlice';

export const useNotifications = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();

    const enableNotifications = useCallback(
        async () => {
            try {
                const { result } = await bridge.send('VKWebAppAllowNotifications');
    
                if (result) {
                    dispatch(saveUserSettings({ are_notifications_enabled: true }));

                    router.replace({ view: VIEW.TESTS, panel: PANEL.TESTS });
                }
            } catch (error) {
                console.log(error);
            }
        },
        []
    );

    const disableNotifications = useCallback(
        async () => {
            try {
                const { result } = await bridge.send('VKWebAppDenyNotifications');

                if (result) {
                    dispatch(saveUserSettings({ are_notifications_enabled: false }));
                }
            } catch (error) {
                console.log(error);
            }

            router.replace({ view: VIEW.TESTS, panel: PANEL.TESTS });
        },
        []
    );

    return {
        enableNotifications,
        disableNotifications,
    };
};
