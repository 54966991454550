import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import bridge from '@vkontakte/vk-bridge';

import store from './store';
import App from './App';

import '@vkontakte/vkui/dist/vkui.css';

// Init VK Mini App
bridge.send('VKWebAppInit', {});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
