import React from 'react';
import { CardGrid } from '@vkontakte/vkui';

import { TestListProps } from './TestList.types';
import { TestCard } from '../TestCard';
import { AddingTestCard } from '../AddingsTestCard';

export const TestList: React.FC<TestListProps> = ({ tests, isDesktop }) => {
  const hasTests = Boolean(tests.length);

  return (
    <CardGrid
      size={isDesktop ? 'm': 'l'}
      style={{ alignItems: 'stretch' }}
    >
      {tests.map((test) => (
        <TestCard
          test={test}
          key={`${test.short_name}_test`}
        />
      ))}
      {hasTests && <AddingTestCard />}
    </CardGrid>
  );
};
