import { connect } from 'react-redux';

import { RootState } from '../../../store';
import { selectTestCanRedo } from '../../../store/testSlice/testSelectors';

import { TestNavigation } from './TestNavigation';

function mapStateToProps(state: RootState) {
  return {
    canRedo: selectTestCanRedo(state),
  };
}

export default connect(mapStateToProps)(TestNavigation);
