import React, { useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { useSelector } from 'react-redux';
import { useAppDispatch } from './hooks/useAppDispatch';

import {
  usePlatform,
  Platform,
  useAdaptivityWithJSMediaQueries,
  ConfigProvider,
  AdaptivityProvider,
} from '@vkontakte/vkui';

import { usePopout } from './hooks/usePopout';
import { useRouter } from './hooks/useRouter';
import { useAppConfig } from './hooks/useAppConfig';
import { useIOS } from './hooks/useIOS';

import { updateSettings } from './store/settingsSlice';
import { selectAppearance } from './store/settingsSlice/selectors';

import { fetchTests } from './store/testsSlice';
import { fetchUser, updateUser } from './store/userSlice';
import { fetchResults } from './store/resultsSlice';
import { fetchTest } from './store/testSlice';

import { PANEL } from './constants/Panel';
import { POPOUT } from './constants/Popout';

import MainViews from './views';

export const App: React.FC = () => {
  const platform = usePlatform();
  const { isDesktop } = useAdaptivityWithJSMediaQueries();

  const dispatch = useAppDispatch();
  const appearance = useSelector(selectAppearance);

  const router = useRouter();
  const { setPopout, resetPopout } = usePopout();

  useEffect(() => {
    // TODO: мб перенести в место получше
    // Первоначальная загрузка списка доступных тестов,
    // пользовательских настроек и результатов
    // прошлых прохождений
    dispatch(fetchTests({ withPercent: true }))
      .then((action) => action.payload)
      .then(async (tests) => {
        if (!Array.isArray(tests)) {
          return;
        }

        // Test by link
        const testShortNames = tests.map((test) => test.short_name);
        const testShortName = window.location.hash.startsWith('#')
          ? window.location.hash.slice(1)
          : window.location.hash;

        if (testShortName && testShortNames.includes(testShortName)) {
          setPopout(POPOUT.SMART_SPINNER);
          await dispatch(fetchTest(testShortName));
          resetPopout();
          router.forward({ panel: PANEL.CURRENT_TEST });
        }
      });
    dispatch(fetchUser())
      .then((action) => action.payload)
      .then(async (userData) => {
        if (!userData || typeof userData !== 'object') {
          return;
        }

        const hasPolicyAcceptedProperty = userData.hasOwnProperty('is_privacy_policy_accepted');
        const isPolicyAccepted = userData.is_privacy_policy_accepted;

        if (!hasPolicyAcceptedProperty || !isPolicyAccepted) {
          setPopout(POPOUT.PRIVACY_POLICY_ALERT);
        }
      });
    dispatch(fetchResults({ isLast: true, isProcessed: true }));

    const initialAppSettings = {
      platform,
      isDesktop,
      hasHeader: platform !== Platform.VKCOM,
    };

    dispatch(updateSettings(initialAppSettings));

    bridge.subscribe(({ detail: { type, data } }) => {
      if (type === 'VKWebAppUpdateConfig') {
        // Удаляем id приложения, который приходит из этого метода,
        // так как на мобильных устройствах он не совпадает с реальным id,
        // что приводит к ошибке при обращении к другим методам
        // @ts-ignore
        delete data.app_id;
        dispatch(updateSettings(data));
      }
    });

    bridge.send('VKWebAppGetUserInfo')
      .then((data) => dispatch(updateUser(data)));

    bridge.send('VKWebAppGetLaunchParams')
      .then(({ vk_access_token_settings: scope }) => dispatch(updateUser({
        granted_token_scope: Boolean(scope) ? scope.split(',') : []
      })));

  }, [dispatch, platform, isDesktop]);

  useIOS();
  useAppConfig();

  return (
    <ConfigProvider appearance={appearance}>
      <AdaptivityProvider>
        <MainViews/>
      </AdaptivityProvider>
    </ConfigProvider>
  );
};

export default App;
