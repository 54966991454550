export const EVENT_TYPE = {
  REPOST: 'repost',
  FOLLOW: 'follow',
  STORY: 'story',
  IMAGE_GENERATION: 'image_generation',
} as const;

export const EVENT_NAME = {
  SHARE_APP_SUCCESS: 'successfully_shared_app_on_wall',
  SHARE_APP_FAILURE: 'failed_to_share_app_on_wall',
  OPEN_LABORATORY_SITE: 'laboratory_site_opened_by_link',
  TEMPERAMENT_REPOST_SUCCESS: 'successfully_shared_temperament_test_results_on_wall',
  TEMPERAMENT_REPOST_FAILURE: 'failed_to_share_temperament_test_results_on_wall',
  SE_SITUATIONS_STORY_SUCCESS: 'successfully_uploaded_se_situations_test_results_in_story',
  SE_SITUATIONS_STORY_FAILURE: 'failed_to_upload_se_situations_test_results_in_story',
  GOLLAND_SUCCESS_GENERATION: 'successfully_generated_image_was_shown',
  GOLLAND_DURING_GENERATION_NO_USER_AVATAR: 'avatar_was_not_found_during_generation',
  GOLLAND_DURING_GENERATION_NO_FACE_ON_AVATAR: 'face_in_the_avatar_was_not_found_during_generation',
  GOLLAND_DURING_GENERATION_UNKNOWN_ERROR: 'unknown_error_during_generation',
  GOLLAND_CLICK_REGENERATE_BUTTON: 'user_clicked_on_regenerate_image_button',
  GOLLAND_CLICK_GENERATE_BUTTON: 'user_clicked_generate_button_after_refusing_to_generate_or_test_was_without_question',
  GOLLAND_SHARE_ON_WALL_SUCCESS: 'successfully_shared_golland_test_results_on_wall',
  GOLLAND_SHARE_ON_WALL_FAILURE: 'failed_to_share_golland_test_results_on_wall'
} as const;
