import { connect } from 'react-redux';

import { RootState } from '../../../store';
import { selectResults } from '../../../store/resultsSlice/resultsSelectors';

import { ResultList } from './ResultList';

function mapStateToProps(state: RootState) {
  return {
    results: selectResults(state),
  };
}

export default connect(mapStateToProps)(ResultList);
