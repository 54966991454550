import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';

export const selectLaunchParams = (state: RootState) => state.launchParams.data;

export const selectSign = createSelector(
    selectLaunchParams,
    (launchParams) => launchParams.sign
);

export const selectAppId = createSelector(
    selectLaunchParams,
    (launchParams) => launchParams.vk_app_id
);

export const selectAreNotificationsEnabled = createSelector(
    selectLaunchParams,
    (launchParams) => launchParams.vk_are_notifications_enabled
);
