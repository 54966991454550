import React from 'react';

import { Div, Placeholder } from '@vkontakte/vkui';
import { Icon56GestureOutline } from '@vkontakte/icons';

export type DefaultHandlerType = {
  header?: string;
  content?: string;
};

const DEFAULT_CONTENT = 'Спасибо за прохождение теста! Мы пока не можем показать Ваши результаты в красивом виде, но уже решаем этот вопрос';

export const DefaultHandler: React.FC<DefaultHandlerType> = ({
  header,
  content = DEFAULT_CONTENT,
}) => {
  return (
    <Div>
      <Placeholder
        icon={<Icon56GestureOutline/>}
        header={header}
      >
        {content}
      </Placeholder>
    </Div>
  );
};
