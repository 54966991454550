import React from 'react';

import { OtherFactorsDesktop } from './OtherFactorsDesktop';
import { OtherFactorsMobile } from './OtherFactorsMobile';
import { OtherFactorsMobileProps } from './types';

export const OtherFactors: React.FC<OtherFactorsMobileProps> = ({ isDesktop, ...otherProps }) => {
  return (
    isDesktop ? (
      <OtherFactorsDesktop {...otherProps} />
    ) : (
      <OtherFactorsMobile {...otherProps} />
    )
  );
};
