import { Title } from '@vkontakte/vkui';

import { HorizontalSlides }  from './../../../../../../components/common/HorizontalSlides';
import styles from './TestReacommendation.module.css';
import { TestRecommendationProps } from './types';

export const TestRecommendation: React.FC<TestRecommendationProps> = ({ pagesOptions }) => {
  const render = () => {
    if (pagesOptions.length == 0){
      return <></>;
    }

    return (
      <div>
        <div className={styles.titleContainer}>   
          <Title>Возможно, вам также будет интересно</Title>
        </div>
        <HorizontalSlides pagesOptions={pagesOptions}/>
      </div>
    );
  }
  return (
    render()
  );
};
