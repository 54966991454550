import React from 'react';

import { View } from '@vkontakte/vkui';

import { ViewType } from '../../constants/View';
import { PANEL, PanelType } from '../../constants/Panel';

import { SettingsPanel } from './panels/SettingsPanel';

export type SettingsViewPropsType = {
  id: ViewType;
  activePanel: PanelType;
};

export const SettingsView: React.FC<SettingsViewPropsType> = ({ id, activePanel }) => {

  return (
    <View id={id} activePanel={activePanel}>
      <SettingsPanel id={PANEL.SETTINGS}/>
    </View>
  );
};
