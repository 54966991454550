import React from 'react';

import {
  ContentCard,
  Caption,
} from '@vkontakte/vkui';

import { TestCardProps } from './TestCard.types';

import styles from './TestCard.module.css';

import defaultImageLight from '../../../images/testDefaultImageLight.png';
import defaultImageDark from '../../../images/testDefaultImageDark.png';

export const TestCard: React.FC<TestCardProps> = ({
  test,
  isDesktop,
  appearance,
  setTest,
  percentInfo,
  hasSkippableQuestions,
}) => {
  const {
    short_name,
    full_name,
    decorations: {
      photo,
      description,
      passing_time,
    },
  } = test;

  const renderPhoto = () => {
    if (!photo || photo === 'default') {
      return appearance === 'light'
        ? defaultImageLight
        : defaultImageDark;
    }

    return photo;
  };

  const maybeRenderProgress = () => {
    if (!percentInfo) {
      return null;
    }
    
    const {
      answeredQuestionsCount,
      questionsCount,
      formattedPercent,
    } = percentInfo;

    if (!answeredQuestionsCount) {
      return;
    }

    return (
      <Caption level='1' data-testid='progress'>
        {`Пройден на ${formattedPercent}${hasSkippableQuestions ? '' : ` (${answeredQuestionsCount}/${questionsCount})`}`}
      </Caption>
    );
  };

  const renderTestName = () => {
    if (!full_name) {
      return null;
    }

    return (
      <span className={styles.testName}>
        {full_name}
      </span>
    );
  };

  const renderTrimmedDescription = () => {
    if (!description) {
      return null;
    }

    const DESCRIPTION_MAX_LENGTH = 300;
    const isGreaterThanMax = description.length > DESCRIPTION_MAX_LENGTH;

    return isGreaterThanMax ? (
      <>{`${description.slice(0, DESCRIPTION_MAX_LENGTH).trim()}...`}</>
    ) : (
      <>{description}</>
    );
  };

  const maybeRenderPassingTime = () => {
    if (!passing_time) {
      return null;
    }
    
    return (
      <div
        className={styles.passingTime}
        data-testid='passingTime'
      >
        Среднее время прохождения: {passing_time} мин.
      </div>
    );
  };

  return (
    <ContentCard
      src={renderPhoto()}
      maxHeight={isDesktop ? 140 : 180}
      subtitle={maybeRenderProgress()}
      header={renderTestName()}
      text={renderTrimmedDescription()}
      caption={maybeRenderPassingTime()}
      onClick={() => setTest(short_name)}
    />
  );
};
