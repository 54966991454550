import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import bridge from '@vkontakte/vk-bridge';

import { selectLocation } from '../../../../../store/routerSlice/routerSelectors';
import { POPOUT } from '../../../../../constants/Popout';
import { OUTPUT_HANDLER } from '../../../../../constants/OutputHandler';
import { usePopout } from '../../../../../hooks/usePopout';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { useSetAccessToken } from '../../../../../hooks/useSetAccessToken';
import { RootState } from '../../../../../store';
import { selectUserAccessToken, selectUserVKID } from '../../../../../store/userSlice/userSelectors';
import { selectDataByHandlerId } from '../../../../../store/resultSlice/resultSliceSelectors';
import { selectIsDesktop } from '../../../../../store/settingsSlice/selectors';
import { selectAppId } from '../../../../../store/launchParamsSlice/selectors';
import { setPopout as setPopoutWithMetaInfo } from '../../../../../store/popoutSlice';
import { isObject } from '../../../../../utils/isObject';
import { deepCopy } from '../../../../../utils/deepCopy';
import eventsApi from '../../../../../api/EventsApi';
import { EVENT_NAME, EVENT_TYPE } from '../../../../../constants/Event';

import { getStoryImage, splitText } from './utils/canvas';
import { uploadShareResultImageToVk } from './utils/vk';
import { DataForSharing } from './types';
import {
  GOLLAND_TEST_SHORT_NAME,
  USER_DENIED_ERROR_MESSAGE,
  OPERATION_DENIED_BY_USER_ERROR_MESSAGE
} from './constants';

const useDataForSharing = (): DataForSharing | undefined => {
  const modalData = useSelector(
    (state: RootState) => selectDataByHandlerId(state, OUTPUT_HANDLER.MODAL)
  );

  if (
    !modalData ||
      typeof modalData !== 'object' ||
      !('imageToShow' in modalData) ||
      !('story' in modalData) ||
      !('factor' in modalData)
  ) {
    return undefined;
  }

  const factor = modalData.factor;

  if (
    !factor ||
      typeof factor !== 'object' ||
      !('name' in factor) ||
      !('short_description' in factor) ||
      !('story_mobile_description' in factor)
  ) {
    return undefined;
  }

  return {
    factorName: factor.name,
    description: factor.short_description,
    storyMobileDescription: factor.story_mobile_description,
    imageToShow: modalData.imageToShow,
    story: modalData.story,
  } as DataForSharing;
};

const useShowErrorPopout = (message: string) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(setPopoutWithMetaInfo({
      type: POPOUT.ERROR_SNACKBAR,
      meta_info: { message },
    }));
  }, [dispatch]);
};

export const useShareInStory = () => {
  const location = useSelector(selectLocation);
  const vkId = useSelector(selectUserVKID);
  const dataForSharing = useDataForSharing();
  const showStoryErrorPopout = useShowErrorPopout('Произошла ошибка при попытке поделиться в истории. Попробуйте снова!');
  const isDesktop = useSelector(selectIsDesktop);

  return useCallback(async () => {
    if (!dataForSharing) {
      showStoryErrorPopout();
      return null;
    }

    try {
      const {
        factorName,
        description,
        imageToShow,
        story: storyTemplate ,
        storyMobileDescription,
      } = dataForSharing;

      const route = {
        ...location,
        test_short_name: 'golland'
      };
      const storyBoxToShow = deepCopy(storyTemplate.story_box);

      if (imageToShow && storyBoxToShow && storyBoxToShow.stickers) {
        const [
          imageSticker,
          titleSticker,
          descriptionSticker
        ] = storyBoxToShow.stickers;
        // @ts-ignore
        imageSticker.sticker.blob = await getStoryImage(imageToShow);
        // @ts-ignore
        titleSticker.sticker.action.text = factorName;
        if (isDesktop) {
          // @ts-ignore
          descriptionSticker.sticker.action.text = splitText(description, 40);
        } else {
          imageSticker.sticker.transform.translation_y = 0.025;
          titleSticker.sticker.transform.translation_y = 0.09;
          descriptionSticker.sticker.transform.translation_y = 0.662;
          descriptionSticker.sticker.transform.relation_width = 0.67;
          // @ts-ignore
          descriptionSticker.sticker.action.text = storyMobileDescription;
        }

        await bridge.send('VKWebAppShowStoryBox', storyBoxToShow)
          .then((data) => {
            const event = storyTemplate.successfulEvent;
            if (event && isObject(event) && vkId !== undefined) {
              eventsApi.sendStatistics({ data, route, vk_id: vkId, ...event });
            }
          })
          .catch((error) => {
            const event = storyTemplate.failedEvent;
            if (event && isObject(event) && vkId !== undefined) {
              eventsApi.sendStatistics({ data: error, route, vk_id: vkId, ...event });
            }
            if (error.error_data.error_reason !== USER_DENIED_ERROR_MESSAGE) {
              throw error;
            }
          });
      }
    } catch {
      showStoryErrorPopout();
    }
  }, [dataForSharing, showStoryErrorPopout, location, vkId]);
};

const useSendStatisticWhenShareOnWall = () => {
  const vkId = useSelector(selectUserVKID);
  const location = useSelector(selectLocation);
  const eventBaseParams = useMemo(() => {
    if (!vkId) {
      return undefined;
    }

    return {
      type: EVENT_TYPE.REPOST,
      vk_id: vkId,
      route: {
        ...location,
        test_short_name: 'golland',
      },
    };
  }, [location, vkId]);

  const sendSuccessStatistic = useCallback((data: {[p: string]: any}) => {
    if (!eventBaseParams) {
      return;
    }

    eventsApi.sendStatistics({
      ...eventBaseParams,
      name: EVENT_NAME.GOLLAND_SHARE_ON_WALL_SUCCESS,
      data,
    });
  }, [eventBaseParams]);

  const sendErrorStatistic = useCallback((data: {[p: string]: any}) => {
    if (!eventBaseParams) {
      return;
    }

    eventsApi.sendStatistics({
      ...eventBaseParams,
      name: EVENT_NAME.GOLLAND_SHARE_ON_WALL_FAILURE,
      data,
    });
  }, [eventBaseParams]);

  return {
    sendSuccessStatistic,
    sendErrorStatistic,
  };
};

export const useShareOnWall = () => {
  const appId = useSelector(selectAppId);
  const accessToken = useSelector(selectUserAccessToken);
  const dataForSharing = useDataForSharing();
  const { setPopout, resetPopout } = usePopout();
  const { sendErrorStatistic, sendSuccessStatistic } = useSendStatisticWhenShareOnWall();
  const setAccessToken = useSetAccessToken(GOLLAND_TEST_SHORT_NAME);
  const showWallErrorPopout = useShowErrorPopout('Произошла ошибка при попытке поделиться на стене. Попробуйте снова!');

  useEffect(() => {
    setAccessToken();
  }, []);

  return useCallback(async () => {
    if (!dataForSharing || !accessToken) {
      showWallErrorPopout();
      return null;
    }

    try {
      const { factorName, description, imageToShow } = dataForSharing;

      setPopout(POPOUT.SMART_SPINNER);
      const { id, owner_id } = await uploadShareResultImageToVk(
        factorName,
        description,
        imageToShow,
        accessToken,
        appId,
      );
      resetPopout();

      await bridge.send('VKWebAppShowWallPostBox', {
        message: `У меня ${factorName.charAt(0).toLowerCase() + factorName.slice(1)} личности! Хочешь узнать свой профессиональный тип и какие профессии тебе подходят? Заходи в приложение «Психологические тесты» — https://vk.com/${Boolean(appId) ? `app${appId}` : 'ticspsytests'}`,
        attachments: `photo${owner_id}_${id},https://vk.com/${Boolean(appId) ? `app${appId}` : 'ticspsytests'}`,
      })
        .then((data) => {
          sendSuccessStatistic(data);
        })
        .catch((error) => {
          sendErrorStatistic(error);
          const reason = error.error_data.error_reason;
          if (reason !== OPERATION_DENIED_BY_USER_ERROR_MESSAGE && reason !== USER_DENIED_ERROR_MESSAGE) {
            throw error;
          }
        });
    } catch {
      showWallErrorPopout();
    }
  }, [
    dataForSharing,
    accessToken,
    showWallErrorPopout,
    setPopout,
    appId,
    resetPopout,
    sendSuccessStatistic,
    sendErrorStatistic
  ]);
};
