import React from 'react';

import { Headline, Spacing, Caption, classNames } from '@vkontakte/vkui';

import ProgressiveImage from 'react-progressive-graceful-image';

import { FeatureContent } from '../FeatureContent';
import { Paragraphs } from '../Paragraphs';

import styles from './CompactFactorDescription.module.css';
import { GeneralFactorDescriptionProps } from './types';

export const CompactFactorDescription: React.FC<GeneralFactorDescriptionProps> = ({
  photoSrc,
  factorName,
  description,
  feature,
  isDesktop,
  photoPlaceholderSrc,
}) => {
  const textContent = (
    <div className={styles.textContentContainer}>
      {
        !isDesktop && (
          <>
            <Spacing size={12} />
            <Headline weight="2" className={styles.title}>
              {factorName}
            </Headline>
            <Spacing size={8} />
          </>
        )
      }
      <Paragraphs
        text={description}
        paragraphClassName={styles.paragraph}
        spacing={8}
        textComponent={Caption}
      />
      <Spacing size={16} />
      {
        feature && <FeatureContent feature={feature} />
      }
    </div>
  );

  return (
    <div className={classNames(styles.container, isDesktop && styles.desktopContainer)}>
      <ProgressiveImage
        src={photoSrc}
        placeholder={photoPlaceholderSrc}
      >
        {(src, loading) => (
          <img
            src={src}
            alt="Персонаж"
            className={styles.image}
            style={{ filter: loading ? 'blur(8px)' : 'blur(0)' }}
          />
        )}
      </ProgressiveImage>
      {textContent}
    </div>
  );
};
