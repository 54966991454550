import React, { useEffect } from 'react';

import {
  FormLayout,
  FormItem,
  Slider,
} from '@vkontakte/vkui';

import { QuestionHandlerPropsType } from '../../test/Answer/Answer';

type SliderAnswerType = {
  a_id: number;
}

export const SliderHandler: React.FC<QuestionHandlerPropsType> = ({
  possibleAnswers,
  givenAnswers,
  answer,
}) => {
  const getIndex = () => {
    const index = possibleAnswers.findIndex(
      ({ a_id }) => (givenAnswers as SliderAnswerType[])[0]?.a_id === a_id
    );
    return (index !== -1) ? index : 0;
  };

  const onSliderChange = (value: number) => {
    answer([{ a_id: possibleAnswers[value].a_id }]);
  };

  return (
    <FormLayout>
      <FormItem top={possibleAnswers[getIndex()].content}>
        <Slider
          min={0} 
          max={possibleAnswers.length - 1} 
          step={1} 
          defaultValue={0}
          value={getIndex()}
          onChange={onSliderChange}
        />
      </FormItem>
    </FormLayout>
  );
};