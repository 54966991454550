import React from 'react';
import { connect, useSelector } from 'react-redux';
import { throttle } from '@vkontakte/vkjs';

import { RootState } from '../../../../store';
import { selectIsDesktop, selectPlatform } from '../../../../store/settingsSlice/selectors';
import { selectUserVKID } from '../../../../store/userSlice/userSelectors';
import { selectLocation } from '../../../../store/routerSlice/routerSelectors';
import { selectTestShortName } from '../../../../store/testSlice/testSelectors';

import { ModalType } from '../../../../constants/Modal';
import { EVENT_TYPE, EVENT_NAME } from '../../../../constants/Event';

import { useRouter } from '../../../../hooks/useRouter';

import eventsApi from '../../../../api/EventsApi';

import { AboutUsModal } from './AboutUsModal';

type ContainerPropsType = {
  id: ModalType;
  platform: string;
  isDesktop: boolean;
};

const AboutUsModalContainer: React.FC<ContainerPropsType> = (props) => {
  const router = useRouter();

  const location = useSelector(selectLocation);
  const testShortName = useSelector(selectTestShortName);
  const vkId = useSelector(selectUserVKID);

  const openURL = throttle((URL: string) => {
    setTimeout(() => window.open(URL, props.isDesktop ? '_blank' : '_top'));

    if (!vkId) {
      return;
    }

    eventsApi.sendStatistics({ 
      type: EVENT_TYPE.FOLLOW,
      name: EVENT_NAME.OPEN_LABORATORY_SITE,
      vk_id: vkId,
      route: {
        ...location,
        test_short_name: testShortName,
      },
    });
  }, 2500);

  return (
    <AboutUsModal
      {...props}
      onBackClick={router.back}
      openURL={openURL}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    platform: selectPlatform(state),
    isDesktop: selectIsDesktop(state),
  };
}

export default connect(mapStateToProps)(AboutUsModalContainer);
