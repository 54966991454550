import React from 'react';

import { Avatar, Caption, classNames } from '@vkontakte/vkui';

import ProgressiveImage from 'react-progressive-graceful-image';

import styles from './CompactCell.module.css';
import { CompactCellProps } from './types';

export const CompactCell: React.FC<CompactCellProps> = ({
  title,
  photoSrc,
  photoPlaceholderSrc,
  className,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.avatarContainer}>
        {
          photoPlaceholderSrc ? (
            <ProgressiveImage
              src={photoSrc}
              placeholder={photoPlaceholderSrc}
            >
              {(src, loading) => (
                <div>
                  <Avatar
                    size={56}
                    src={src}
                    style={{ filter: loading ? 'blur(8px)' : 'blur(0)' }}
                  />
                </div>
              )}
            </ProgressiveImage>
          ) : (
            <Avatar size={56} src={photoSrc} />
          )
        }
      </div>
      <div className={styles.textContainer}>
        <Caption className={styles.text}>
          {title}
        </Caption>
      </div>
    </div>
  );
};
