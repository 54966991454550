import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import testsApi, { GetTestsQueryParams } from '../../api/TestsApi';
import { TestType } from '../../types/test.type';

import { fetchTest } from '../testSlice';

import { isError } from '../../utils/isError';

type TestsStateType = {
  data: TestType[],
  loading: boolean;
  error: string | null;
};

export const fetchTests = createAsyncThunk<TestType[] | undefined, GetTestsQueryParams | undefined, { rejectValue: string }>(
  'tests/fetchTests',
  async function (queryParams, { rejectWithValue, dispatch }) {
    try {
      const data: TestType[] = await testsApi.getTests(queryParams);
      return data;
    }
    catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

const testsInitialState: TestsStateType = {
  data: [],
  loading: false,
  error: null,
};

const testsSlice = createSlice({
  name: 'tests',
  initialState: testsInitialState,
  reducers: {
    updateTestFromArray(state, action: PayloadAction<TestType>) {
      state.data.forEach((test) => {
        if (test.short_name === action.payload.short_name) {
          Object.assign(test, action.payload);
        }
      });
    },
    updateAnsweredQuestionsCountByTestShortName(state, action: PayloadAction<{ testShortName: string, count: number }>) {
      const { testShortName, count } = action.payload;
      
      state.data.forEach((test) => {
        if (test.short_name === testShortName) {
          if (!test.additional_info) {
            return;
          }

          test.additional_info.answered_questions_count = count;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTests.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload;
        }
        state.loading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export const {
  updateTestFromArray,
  updateAnsweredQuestionsCountByTestShortName,
} = testsSlice.actions;

export default testsSlice.reducer;
