import { createSlice } from '@reduxjs/toolkit';

import { historyOperation } from '../../constants/History';

import { ROUTER_SLICE_NAME, ROUTER_INITIAL_STATE } from './constants';

const routerSlice = createSlice({
    name: ROUTER_SLICE_NAME,
    initialState: ROUTER_INITIAL_STATE,
    reducers: {
        updateLocation(state, action) {
            const previousLength = state.data.location.length;
            const currentLength = action.payload.length;

            if (previousLength < currentLength) {
                state.data.operation = historyOperation.PUSH;
            } else if (previousLength === currentLength) {
                state.data.operation = historyOperation.REPLACE;
            } else {
                state.data.operation = historyOperation.POP;
            }

            Object.assign(state.data.location, action.payload);
        },
    },
});

export const { updateLocation } = routerSlice.actions;

export default routerSlice.reducer;
