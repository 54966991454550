import { RootState } from '..';

export const selectResults = (state: RootState) => state.results.data;

export const selectResultByTestShortName = (state: RootState, testShortName: string) => {
  return selectResults(state).find(({ test_short_name }) => test_short_name === testShortName);
};

export const selectLoading = (state: RootState) => state.results.loading;

export const selectError = (state: RootState) => state.results.error;
