import { connect } from 'react-redux';

import { RootState } from '../../../store';
import {
  selectUserVKID,
  selectUserFullName,
  selectUserFirstEntryDate,
  selectUserPhoto,
} from '../../../store/userSlice/userSelectors';

import { Profile } from './Profile';

const FULLNAME_STUB = 'Unknown User';

function mapStateToProps(state: RootState) {
  return {
    vkId: selectUserVKID(state) || 0,
    fullName: selectUserFullName(state) || FULLNAME_STUB,
    firstEntryDate: selectUserFirstEntryDate(state),
    photo: selectUserPhoto(state),
  };
}

export default connect(mapStateToProps)(Profile);
