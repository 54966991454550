import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

import { isEmailValid } from '../../utils/isEmailValid';

export const selectAddingTestForm = (state: RootState) => state.addingTestForm;

export const selectAddingsTestFormData = createSelector(
  selectAddingTestForm,
  (addingTestForm) => addingTestForm.data,
);

export const selectAddingTestFormFirstName = createSelector(
  selectAddingsTestFormData,
  (addingTestFormData) => addingTestFormData.firstName,
);

export const selectAddingTestFormLastName = createSelector(
  selectAddingsTestFormData,
  (addingTestFormData) => addingTestFormData.lastName,
);

export const selectAddingTestFormEmail = createSelector(
  selectAddingsTestFormData,
  (addingTestFormData) => addingTestFormData.email,
);

export const selectAddingTestFormOrganization = createSelector(
  selectAddingsTestFormData,
  (addingTestFormData) => addingTestFormData.organization,
);

export const selectAddingTestFormText = createSelector(
  selectAddingsTestFormData,
  (addingTestFormData) => addingTestFormData.text,
);

export const selectIsFormValid = createSelector(
  selectAddingsTestFormData,
  ({ firstName, lastName, email, text }) => {
    return (
      firstName !== '' &&
      lastName !== '' &&
      email !== '' && isEmailValid(email) && 
      text !== ''
    );
  },
);

export const selectWasSent = createSelector(
  selectAddingTestForm,
  (addingTestForm) => addingTestForm.wasSent,
);

export const selectLoading = createSelector(
  selectAddingTestForm,
  (addingTestForm) => addingTestForm.loading,
);

export const selectError = createSelector(
  selectAddingTestForm,
  (addingTestForm) => addingTestForm.error,
);
