import React from 'react';

import { Headline } from '@vkontakte/vkui';

import { CompactFactorDescription } from '../../CompactFactorDescription';
import { CardForScroll, UpgradedCardScroll } from '../../cardScroll';

import styles from './OtherFactorsMobile.module.css';
import { OtherFactorsMobileProps } from './types';

export const OtherFactorsMobile: React.FC<OtherFactorsMobileProps> = ({ slideWidth, otherFactors }) => {
  return (
    <div>
      <div className={styles.titleContainer}>
        <Headline weight="2" className={styles.title}>
                Другие типы личности
        </Headline>
      </div>

      <UpgradedCardScroll needShowBullets slideWidth={slideWidth}>
        {
          otherFactors.map(({ name, features, description, character_images }, index) => {
            const feature = features.find(feature => feature.hasOwnProperty('show_with_description') && feature.show_with_description);
            return (
              <CardForScroll isFirst={index === 0} isLast={index === otherFactors.length - 1}>
                <CompactFactorDescription
                  isDesktop={false}
                  photoSrc={character_images.preview.photo_src}
                  photoPlaceholderSrc={character_images.preview.photo_placeholder}
                  factorName={name}
                  description={description}
                  feature={feature}
                />
              </CardForScroll>
            );
          })
        }
      </UpgradedCardScroll>
    </div>
  );
};
