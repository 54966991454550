import { connect } from 'react-redux';

import { RootState } from '../../../../store';
import { selectError, selectLoading } from '../../../../store/resultsSlice/resultsSelectors';
import { selectIsDesktop } from '../../../../store/settingsSlice/selectors';

import { ProfilePanel } from './ProfilePanel';

function mapStateToProps(state: RootState) {
  return {
    isDesktop: selectIsDesktop(state),
    resultsLoading: selectLoading(state),
    resultsError: selectError(state),
  };
}

export default connect(mapStateToProps)(ProfilePanel);
