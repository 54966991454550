import api from '../../../../api/api';

const swapFaces = async (userPhotoUrl: string, characterUrl: string) => {
  const { imageUrl } = await api('images/swap-faces', {
    url_face_from: userPhotoUrl,
    url_face_to: characterUrl
  });

  return imageUrl;
};

export default swapFaces;
