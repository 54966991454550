import React from 'react';

import {
  Div,
  Title,
  Paragraph,
  List,
  SimpleCell,
  Avatar,
  Button,
  ButtonGroup,
  calcInitialsAvatarColor,
} from '@vkontakte/vkui';

import { getInitialLetters } from '../../../utils/getInitialLetters';

import { ShowStoryBoxOptions } from '@vkontakte/vk-bridge';

import styles from './SituationsFriendsHandler.module.css';

export type StoryType = {
  story_box: ShowStoryBoxOptions;
  successfulEvent?: {
    type: string;
    name: string;
  };
  failedEvent?: {
    type: string;
    name: string;
  };
};

export type SituationsFriendsHandlerType = {
  header: string;
  topPriority: {
    title: string;
    friend: {
      id: number | string;
      photo: string;
      fullName: string;
    };
  };
  vulnerability: {
    title: string;
    assessment: string;
  };
  restPriorities: {
    title: string;
    friends: {
      id: number | string;
      photo: string;
      fullName: string;
    }[];
  };
  story: StoryType;
  shareResultInStory: (story: StoryType) => void;
  userVKID?: number;
  userPhoto?: string;
  userFullName: string;
  isDesktop: boolean;
};

export const SituationsFriendsHandler: React.FC<SituationsFriendsHandlerType> = ({
  header,
  topPriority,
  vulnerability,
  restPriorities,
  story,
  shareResultInStory,
  userVKID,
  userPhoto,
  userFullName,
  isDesktop,
}) => {
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  };

  return (
    <Div>
      <Title level='2' style={{ marginBottom: 12 }}>        
        {header}
      </Title>

      <Paragraph>{topPriority.title}</Paragraph>
      <div style={{ marginTop: 6, ...(isDesktop && gridStyle) }}>
        {isDesktop && (
          <SimpleCell
            hasActive={false}
            hasHover={false}
            before={(
              <Avatar
                src={userPhoto}
                initials={getInitialLetters(userFullName)}
                gradientColor={calcInitialsAvatarColor(userVKID || 0)}
              />
            )}
          >
            {userFullName}
          </SimpleCell>
        )}
        <SimpleCell
          hasActive={false}
          hasHover={false}
          before={(
            <Avatar
              src={topPriority.friend.photo}
              initials={getInitialLetters(topPriority.friend.fullName)}
              gradientColor={calcInitialsAvatarColor(Number(topPriority.friend.id))}
            />
          )}
        >
          {topPriority.friend.fullName}
        </SimpleCell>
      </div>
      <div className={styles.titles}>
        <Title level='2'>{vulnerability.title}</Title>
        <Title style={{ fontSize: '3em' }}>{vulnerability.assessment}</Title>
      </div>
      {Boolean(restPriorities.friends.length) && (
        <>
          <Title level='3'>{restPriorities.title}</Title>
          <List style={{ marginTop: 6 }}>
            {restPriorities.friends.map((friend) => (
              <SimpleCell
                key={friend.id}
                hasActive={false}
                hasHover={false}
                before={(
                  <Avatar
                    src={friend.photo}
                    initials={getInitialLetters(friend.fullName)}
                    gradientColor={calcInitialsAvatarColor(Number(friend.id))}
                  />
                )}
              >
                {friend.fullName}
              </SimpleCell>
            ))}
          </List>
        </>
      )}
      <ButtonGroup
        mode='vertical'
        gap='m'
        stretched
        style={{ marginTop: 24 }}
      >
        <Button
          size='l'
          stretched
          onClick={() => shareResultInStory(story)}
        >
          Поделиться в истории
        </Button>
      </ButtonGroup>
    </Div>
  );
};
