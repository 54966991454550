import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../store';
import {
  selectIsDesktop,
  selectAppearance,
} from '../../../store/settingsSlice/selectors';

import { useRouter } from '../../../hooks/useRouter';
import { PANEL } from '../../../constants/Panel';

import { AddingsTestCard } from './AddingTestCard';
import { AddingTestCardContainerProps } from './AddingsTestCard.types';


const AddingTestFormContainer: React.FC<AddingTestCardContainerProps> = (props) => {
  const router = useRouter();
  const setAddingTestFormPanel = () => router.forward({ panel: PANEL.ADDING_TEST_FORM });

  return (
    <AddingsTestCard
      {...props}
      onClick={setAddingTestFormPanel}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    isDesktop: selectIsDesktop(state),
    appearance: selectAppearance(state),
  };
}

export default connect(mapStateToProps)(AddingTestFormContainer);
