import React from 'react';
import { FormItem, Progress } from '@vkontakte/vkui';

import { ProgressBarProps } from './ProgressBar.types';

export const ProgressBar: React.FC<ProgressBarProps> = ({ value, isDesktop }) => {
  return (
    <FormItem
      id='progresslabel'
      top={`Тест пройден на ${value}%`}
      style={{ paddingTop: isDesktop ? 0 : '' }}
    >
      <Progress aria-labelledby='progresslabel' value={value} />
    </FormItem>    
  );
};
