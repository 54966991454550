import { Button } from '@vkontakte/vkui';
import { AnimatePresence, motion } from 'framer-motion';

import { HorizontalSlidesProps } from './types';
import  styles  from './HorizontalSlides.module.css';
import { VARIANTS } from './constants';

export const HorizontalSlides: React.FC<HorizontalSlidesProps> = ({ pagesOptions, swapPage, page }) => {
  const onClick = () => {
    if (pagesOptions[page].button.onClick != undefined){
      pagesOptions[page].button.onClick?.();
      return;
    }

    swapPage(1);
  };

  return (
      <div className={styles.container}>
          <AnimatePresence initial={false}>
            <motion.div
              className={styles.slide}
              key={page}
              variants={VARIANTS}
              initial='enter'
              animate='center'
              exit='exit'
            >
              <div style={{paddingBottom: 0}}>
              {pagesOptions[page].page}
              </div>
              { pagesOptions.length > 1 && <div className={styles.button}>
                <Button
                  onClick={onClick}
                  mode={pagesOptions[page].button.mode}
                  size='l'
                  stretched
                >
                  {pagesOptions[page].button.text}
                </Button>
              </div>}
            </motion.div>
          </AnimatePresence> 
</div>    
  );
};
