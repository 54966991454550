import React from 'react';

import { Caption, classNames, Spacing } from '@vkontakte/vkui';
import { Icon16CheckOutline } from '@vkontakte/icons';

import styles from './FeatureListContent.module.css';
import { FeatureListContentProps } from './types';

export const FeatureListContent: React.FC<FeatureListContentProps> = ({ feature, needTwoListColumns = false }) => {
  return (
    <div
      className={classNames(
        styles.containerCommon,
        needTwoListColumns ? styles.twoColumnsContainer : styles.oneColumnContainer
      )}
    >
      {
        feature.data.map((property) => (
          <div className={classNames(
            styles.itemContainer,
            needTwoListColumns && styles.itemShorted
          )}>
            <Icon16CheckOutline color='var(--vkui--color_text_secondary)' className={styles.icon} />
            <Spacing size={20} />
            <div className={styles.textContainer}>
              <Caption className={styles.text}>
                {property}
              </Caption>
            </div>
          </div>
        ))
      }
    </div>
  );
};
