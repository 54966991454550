import api from './api';

import { EventType } from '../types/event.type';
import { AddingTestFormType } from '../types/addingTestForm.type';

function sendStatistics(event: EventType) {
  return api('events', event);
}

function sendEmailAboutTest(vkId: number, addingTestFormData: AddingTestFormType) {
  return api('events/send_email_about_test', {
    vk_id: vkId,
    ...addingTestFormData,
  });
}

const eventsApi = { sendStatistics, sendEmailAboutTest };

export default eventsApi;
