import React from 'react';

import { Gallery } from '@vkontakte/vkui';

import styles from './UpgradedCardScroll.module.css';
import { ErrorPlaceholderProps } from './types';

// TODO: CardScroll от vkui на мобильных устройствах работает криво
//  (при скроллинге не происходит остановка на карточке).
//  Поэтому решил использовать для скролла компонент Gallery.
//  Возможно, в новых версиях vkui это исправлено,
//  тогда при переходе на новую версию этот компонент нужно исправить.
export const UpgradedCardScroll: React.FC<ErrorPlaceholderProps> = ({
  children,
  needShowBullets,
  slideWidth,
}) => {
  return (
    //  TODO: На версии 5.0.3 vkui, насколько я понял, align="center" работает криво, пока без него
    <Gallery
      slideWidth={slideWidth}
      bullets={needShowBullets ? 'dark' : undefined}
      style={{ paddingBottom: 16 }}
      className={styles.container}
    >
      {children}
    </Gallery>
  );
};
