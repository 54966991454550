import { connect } from 'react-redux';

import { RootState } from '../../../../store';
import { selectIsDesktop } from '../../../../store/settingsSlice/selectors';
import {
  selectError,
  selectLoading,
} from '../../../../store/testsSlice/testsSelectors';

import { TestsPanel } from './TestsPanel';

function mapStateToProps(state: RootState) {
  return {
    isDesktop: selectIsDesktop(state),
    loading: selectLoading(state),
    error: selectError(state),
  };
}

export default connect(mapStateToProps)(TestsPanel);
