import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useSetAccessToken } from '../../../hooks/useSetAccessToken';

import { resetPopout } from '../../../store/popoutSlice';
import { selectPopoutMetaInfo } from '../../../store/popoutSlice/popoutSelectors';

import { TokenScopeAlert } from './TokenScopeAlert';

export const TokenScopeAlertContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { testShortName, description } = useSelector(selectPopoutMetaInfo);
  const setAccessToken = useSetAccessToken(testShortName || '');

  const triggerAccessTokenRequest = useCallback(async () => {
    dispatch(resetPopout());
    await setAccessToken();
  }, [dispatch, setAccessToken]);

  return (
    <TokenScopeAlert
      triggerAccessTokenRequest={triggerAccessTokenRequest}
      description={description}
    />
  );
};

export default TokenScopeAlertContainer;
