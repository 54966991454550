import { connect } from 'react-redux';
import bridge from '@vkontakte/vk-bridge';

import { RootState } from '../../../store';

import { selectAppId } from '../../../store/launchParamsSlice/selectors';
import { selectIsDesktop } from '../../../store/settingsSlice/selectors';
import { selectLocation } from '../../../store/routerSlice/routerSelectors';
import { selectTestShortName } from '../../../store/testSlice/testSelectors';
import { selectUserVKID } from '../../../store/userSlice/userSelectors';

import { isObject } from '../../../utils/isObject';
import eventsApi from '../../../api/EventsApi';

import {
  ClarificationDescriptionHandler,
  RepostType,
} from './ClarificationDescriptionHandler';

function mapStateToProps(state: RootState) {
  const appId = selectAppId(state);

  const location = selectLocation(state);
  const testShortName = selectTestShortName(state);
  const vkId = selectUserVKID(state);

  const repostResult = (repost?: RepostType) => {
    if (!repost) {
      return;
    }

    bridge
      .send('VKWebAppShowWallPostBox', {
        message: repost.message + '\n\nТы тоже можешь пройти тест в приложении!',
        attachments: repost.attachments + `,https://vk.com/app${appId}`
      })
      .then((data) => {
        const event = repost.successfulEvent;
        if (event && isObject(event) && vkId !== undefined) {
          eventsApi.sendStatistics({
            vk_id: vkId,
            ...event,
            data, 
            route: {
              ...location,
              test_short_name: testShortName,
            },
          });
        }
      })
      .catch((error) => { 
        const event = repost.failedEvent;
        if (event && isObject(event) && vkId !== undefined) {
          eventsApi.sendStatistics({
            vk_id: vkId,
            ...event,
            data: error, 
            route: {
              ...location,
              test_short_name: testShortName,
            },
          });
        } else {
          console.log(error.error_data.error_reason);
        }
      });
  };

  return {
    repostResult,
    isDesktop: selectIsDesktop(state),
  };
}

export default connect(mapStateToProps)(ClarificationDescriptionHandler);
