import { MIN_DISPLACEMENT_OF_PRIMARY_BARS } from './contstants';

export const calculateWidth = (
  score: number,
  isPrimary: boolean,
  minBarWidth: number,
  chartWidth: number,
  minScoreInChart: number,
  maxScoreInChart: number,
) => {
  if (!isPrimary) {
    return chartWidth;
  }

  const maxWidthIncrementOfPrimaryBar = chartWidth - minBarWidth - MIN_DISPLACEMENT_OF_PRIMARY_BARS;
  const scoreIncrement = score - minScoreInChart;
  const scoreMaxMinDifference = maxScoreInChart - minScoreInChart;

  return minBarWidth + maxWidthIncrementOfPrimaryBar * (scoreIncrement / scoreMaxMinDifference);
};
