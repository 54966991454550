import React from 'react';

import { FormLayout } from '@vkontakte/vkui';

import { Form } from './Form';

import { QuestionHandlerPropsType } from '../../test/Answer/Answer';

export const FormHandler: React.FC<QuestionHandlerPropsType> = ({
  possibleAnswers,
  givenAnswers,
  answer,
}) => {
  return (
    <FormLayout>
      {possibleAnswers.map((possibleAnswer) => (
        <Form 
          key={possibleAnswer.a_id}
          possibleAnswer={possibleAnswer}
          givenAnswers={givenAnswers}
          answer={answer}
        />
      ))}
    </FormLayout>
  );
};
