import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { OutputHandlerType } from '../../constants/OutputHandler';

export const selectResultData = (state: RootState) => state.result.data;

export const selectLoading = (state: RootState) => state.result.loading;

export const selectError = (state: RootState) => state.result.error;

export const selectResult = createSelector(
  selectResultData,
  (resultData) => resultData?.result,
);

export const selectDataByHandlerId = createSelector(
  selectResult,
  (_: RootState, output: OutputHandlerType) => output,
  (result, output) => result?.find((item) => item.output === output)?.data,
);
