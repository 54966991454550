import { useState, useCallback } from 'react';

import { setAnnouncementClosed, isAnnouncementClosed } from './utils';

export const useClosed = (id: string) => {
    const [isClosed, setIsClosed] = useState(isAnnouncementClosed(id));

    const handleClose = useCallback(
        () => {
            setAnnouncementClosed(id);
            setIsClosed(true);
        },
        []
    );

    return { isClosed, handleClose };
};
