import { connect } from 'react-redux';

import { RootState } from '../../../store';
import { selectTestShortName } from '../../../store/testSlice/testSelectors';
import { selectIsDesktop } from '../../../store/settingsSlice/selectors';

import { ProgressBar } from './ProgressBar';
import { selectPercentInfoByTestShortName } from '../../../store/testsSlice/testsSelectors';

function mapStateToProps(state: RootState) {
  const testShortName = selectTestShortName(state);
  const { percent } = selectPercentInfoByTestShortName(state, testShortName || '') || {};
  
  const value = Math.round((percent || 0) * 100);

  return {
    isDesktop: selectIsDesktop(state),
    value,
  };
}

export default connect(mapStateToProps)(ProgressBar);
