import { QuestionValidationRules } from '../types/question.type';

export const ERROR_MESSAGE = {
  REQUIRED: 'Вопрос обязателен для ответа',
  MIN_COUNT: 'На вопрос дано недостаточно ответов',
  MAX_COUNT: 'На вопрос дано слишком много ответов',
};

export const validateAnswers = (
  answers: object[], rules?: QuestionValidationRules
) => {
  const initialRules = {
    required: true,
  };

  const {
    required,
    min_count: minCount,
    max_count: maxCount,
  } = Object.assign(initialRules, rules || {});

  if (!required) {
    return;
  }

  if (!answers.length) {
    throw new Error(ERROR_MESSAGE.REQUIRED);
  }

  if (minCount !== undefined && answers.length < minCount) {
    throw new Error(ERROR_MESSAGE.MIN_COUNT);
  }

  if (maxCount !== undefined && answers.length > maxCount) {
    throw new Error(ERROR_MESSAGE.MAX_COUNT);
  }
};
