import React, { ReactNode } from 'react';

export type TextWithAsteriskPropsType = {
  children: ReactNode;
  asteriskColor?: string;
};

export const TextWithAsterisk: React.FC<TextWithAsteriskPropsType> = ({
  children,
  asteriskColor = 'red',
}) => {
  return (
    <>
      {children}
      <span style={{ color: asteriskColor }}> *</span>
    </>
  );
};
