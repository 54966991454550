export const VARIANTS = {
  enter:  {
    x: -window.innerWidth,
    opacity: 0
  }, 
  center: {
    x: 0,
    opacity: 1
  },
  exit:  {
    zIndex: 0,
    x: window.innerWidth,
    opacity: 0,
  }
};

export const MODE_OPTIONS = {
  LINK: 'link',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  OUTLINE: 'outline',
  UNDEFIEND: undefined,
}