import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../store';
import { selectTestInstruction } from '../../../../store/testSlice/testSelectors';
import { selectIsDesktop, selectPlatform } from '../../../../store/settingsSlice/selectors';

import { ModalType } from '../../../../constants/Modal';

import { useRouter } from '../../../../hooks/useRouter';

import { InstructionModal } from './InstructionModal';

type ContainerPropsType = {
  id: ModalType;
  instruction: string | undefined;
  platform: string;
  isDesktop: boolean;
};

const InstructionModalContainer: React.FC<ContainerPropsType> = (props) => {
  const router = useRouter();

  return (
    <InstructionModal
      {...props}
      onBackClick={router.back}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    instruction: selectTestInstruction(state),
    platform: selectPlatform(state),
    isDesktop: selectIsDesktop(state),
  };
}

export default connect(mapStateToProps)(InstructionModalContainer);
