import { AppConfigData, AppConfigState } from './types';

export const DEFAULT_APP_CONFIG: AppConfigData = {
  announcements: null,
  features: null
};

export const APP_CONFIG_INITIAL_STATE: AppConfigState = {
  data: DEFAULT_APP_CONFIG,
  loading: false,
  error: null,
};
