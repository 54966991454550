import { connect } from 'react-redux';

import { RootState } from '../../../store';
import { selectTestFullName } from '../../../store/testSlice/testSelectors';

import { useRouter } from '../../../hooks/useRouter';
import { MODAL } from '../../../constants/Modal';

import { TestLabelContainerProps } from './TestLabel.types';
import { TestLabel } from './TestLabel';

const TestLabelContainer: React.FC<TestLabelContainerProps> = (props) => {
  const router = useRouter();
  const setInstruction = () => router.forward({ modal: MODAL.INSTRUCTION });

  return (
    <TestLabel
      {...props}
      onInfoClick={setInstruction}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    label: selectTestFullName(state),
  };
}

export default connect(mapStateToProps)(TestLabelContainer);
