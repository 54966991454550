const addCharacterImage = (
  image: any,
  canvasContext: any,
  x: number,
  y: number,
  size:number,
  shadowBlur: number = 85,
  strokeWidth: number = 20,
) => {
  canvasContext.shadowColor = 'rgb(255, 255, 255, 0.7)';
  canvasContext.shadowBlur = shadowBlur;
  canvasContext.lineWidth = strokeWidth * 2;
  canvasContext.strokeStyle = 'white';
  canvasContext.arc(x, y, size / 2, 0, 2 * Math.PI);
  canvasContext.stroke();

  canvasContext.save();

  canvasContext.arc(x, y, size / 2, 0, 2 * Math.PI);
  canvasContext.clip();
  canvasContext.drawImage(image, x - size / 2, y - size / 2, size, size);
  canvasContext.restore();
};

const loadNonCashedImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = `${url}?seed=${Date.now()}`;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = e => {
      reject(e);
    };
  });
};

export async function getStoryImage(
  characterImageSrc: string,
) {
  const characterImage = await loadNonCashedImage(characterImageSrc);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return undefined;
  }

  const additionForShadow = 230;
  const strokeWidth = 20;
  const backgroundWidth = characterImage.width + additionForShadow + strokeWidth;
  const backgroundHeight = characterImage.height + additionForShadow + strokeWidth;

  canvas.width = backgroundWidth;
  canvas.height = backgroundHeight;

  addCharacterImage(characterImage, ctx, backgroundWidth / 2, backgroundHeight / 2, characterImage.width);

  return canvas.toDataURL('image/png');
}

const addDescription = (description: string, canvasContext: any, x: number, topY: number, maxWidth: number) => {
  canvasContext.textBaseline = 'top';
  canvasContext.font = '28px Roboto';
  canvasContext.fillStyle = 'white';

  const paragraphs = description.split('\n');
  let nextY = topY;

  paragraphs.forEach(paragraph => {
    const tokens = paragraph.split(' ');

    let firstWordIndex = 0;
    let afterLastWordIndex = 0;
    while (afterLastWordIndex < tokens.length) {
      afterLastWordIndex++;
      while (
        afterLastWordIndex < tokens.length &&
                canvasContext.measureText(tokens.slice(firstWordIndex, afterLastWordIndex + 1).join(' ')).width < maxWidth
      ) {
        afterLastWordIndex++;
      }
      const newLine = tokens.slice(firstWordIndex, afterLastWordIndex).join(' ');
      const newLineMetrics = canvasContext.measureText(newLine);
      canvasContext.fillText(newLine, x - newLineMetrics.width / 2, nextY);
      firstWordIndex = afterLastWordIndex;
      nextY += newLineMetrics.actualBoundingBoxAscent + newLineMetrics.actualBoundingBoxDescent + 8;
    }
    nextY += 12;
  });
};

export async function getWallImage(
  title: string,
  description: string,
  backgroundSrc: string,
  characterImageSrc: string,
): Promise<{ file: Blob | null, base64: string } | null> {
  const backgroundImage = await loadNonCashedImage(backgroundSrc);
  const characterImage = await loadNonCashedImage(characterImageSrc);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }
  const backgroundWidth = backgroundImage.width;
  const backgroundHeight = backgroundImage.height;
  const characterImageSize = backgroundWidth * 0.5;
  const characterImageY = backgroundHeight * 0.35;
  const backgroundCenterX = backgroundWidth / 2;

  canvas.width = backgroundWidth;
  canvas.height = backgroundHeight;

  ctx.drawImage(backgroundImage, 0, 0);

  addCharacterImage(characterImage, ctx, backgroundCenterX, characterImageY, characterImageSize);

  const robotoFont = new FontFace(
    'Roboto',
    'url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format("woff2")',
  );

  const font = await robotoFont.load();
  document.fonts.add(font);

  ctx.font = 'bold 54px Roboto';
  ctx.fillStyle = 'white';
  const titleMeasurements = ctx.measureText(title);
  const titleY = characterImageY + characterImageSize / 2 + titleMeasurements.actualBoundingBoxAscent + 65;
  const titleX = backgroundCenterX - titleMeasurements.width / 2;
  ctx.fillText(title, titleX, titleY);

  addDescription(
    description,
    ctx,
    backgroundCenterX,
    titleY + 35,
    740,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve({ file, base64: canvas.toDataURL('image/png') });
    }, 'image/png');
  });
}

export const splitText = (text: string, maxLettersInRow: number) => {
  const paragraphs = text.split('\n');
  return paragraphs.map(paragraph => {
    const words = paragraph.split(' ');
    const lines: string[] = [];
    let wordIndex = 0;
    let lineLength;
    let currentLine;
    while (wordIndex <= words.length - 1) {
      currentLine = words[wordIndex];
      lineLength = currentLine.length;
      while (wordIndex < words.length - 1 && (lineLength + words[wordIndex + 1].length + 1 <= maxLettersInRow)) {
        wordIndex++;
        currentLine = `${currentLine} ${words[wordIndex]}`;
        lineLength = currentLine.length;
      }
      lines.push(currentLine);
      wordIndex++;
    }

    return lines.join('\n');
  }).join('\n\n');
};

export const hash = async (str: string) => {
  const msgUint8 = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};
