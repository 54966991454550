import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { setPopout } from '../../../store/popoutSlice';
import { POPOUT } from '../../../constants/Popout';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { getRandomItem } from '../../../utils/getRandomItem';
import eventsApi from '../../../api/EventsApi';
import { EVENT_NAME, EVENT_TYPE } from '../../../constants/Event';
import { selectUserVKID } from '../../../store/userSlice/userSelectors';
import { selectLocation } from '../../../store/routerSlice/routerSelectors';

import { DEFAULT_USER_PHOTO_URL, FACE_NOT_RECOGNIZED_ERROR_MESSAGE, POPOUT_DURATION } from './constants';
import swapFaces from './utils/swapFaces';
import { FactorType } from './types';

export const useSetErrorPopout = () => {
  const dispatch = useAppDispatch();

  return useCallback((
    message: string,
    actionText?: string,
    onActionClick?: () => void
  ) => {
    dispatch(setPopout({
      type: POPOUT.ERROR_SNACKBAR,
      meta_info: {
        duration: POPOUT_DURATION,
        message,
        actionText,
        onActionClick,
      },
    }));
  }, [dispatch]);
};

export const useSendStatisticDuringGeneration = () => {
  const vkId = useSelector(selectUserVKID);
  const location = useSelector(selectLocation);

  return useCallback((eventName: string, data: {[p: string]: any} = {}) => {
    if (!vkId) {
      return;
    }
    eventsApi.sendStatistics({
      type: EVENT_TYPE.IMAGE_GENERATION,
      data,
      name: eventName,
      vk_id: vkId,
      route: {
        ...location,
        test_short_name: 'golland',
      },
    });
  }, [location, vkId]);
};

export const useGenerateImage = (
  onGenerationError: () => void,
  onGenerationSuccess: () => void,
) => {
  const [generatedImage, setGeneratedImage] = useState<string | undefined>(undefined);
  const setErrorPopout = useSetErrorPopout();
  const sendStatistic = useSendStatisticDuringGeneration();

  const generateImage = useCallback(async (
    factorToShow: FactorType,
    userPhotoUrl: string | undefined,
  ) => {
    setGeneratedImage(undefined);
    // TODO: Сейчас там по одной фотографии, поэтому запоминать не нужно
    const newCharacterUrl = getRandomItem(factorToShow.character_images.to_generate);

    if (!userPhotoUrl || userPhotoUrl === DEFAULT_USER_PHOTO_URL) {
      setGeneratedImage(newCharacterUrl);
      onGenerationError();
      setErrorPopout('Отсутствует аватар! Установите аватар профиля и попробуйте еще раз!');
      sendStatistic(EVENT_NAME.GOLLAND_DURING_GENERATION_NO_USER_AVATAR);
      return;
    }

    const startGenerationTime = Date.now();

    try {
      const generatedImage = await swapFaces(userPhotoUrl, newCharacterUrl);
      const generationTime = Date.now() - startGenerationTime;
      setGeneratedImage(generatedImage);
      onGenerationSuccess();
      sendStatistic(EVENT_NAME.GOLLAND_SUCCESS_GENERATION, { generationTime });
    } catch (error) {
      const generationTime = Date.now() - startGenerationTime;
      setGeneratedImage(newCharacterUrl);
      onGenerationError();

      if (
        error &&
                typeof error === 'object' &&
                'message' in error && error.message &&
                error.message === FACE_NOT_RECOGNIZED_ERROR_MESSAGE
      ) {
        sendStatistic(EVENT_NAME.GOLLAND_DURING_GENERATION_NO_FACE_ON_AVATAR, { generationTime });
        setErrorPopout('Не нашли лицо! Попробуйте сменить фото и сгенерировать картинку еще раз!');
        return;
      }

      sendStatistic(
        EVENT_NAME.GOLLAND_DURING_GENERATION_UNKNOWN_ERROR,
        error && typeof error === 'object' ? { ...error, generationTime } : { generationTime },
      );
      setErrorPopout(
        'Не удалось сгенерировать картинку из-за ошибки на сервере',
        'Сгенерировать еще раз',
        () => generateImage(factorToShow, userPhotoUrl)
      );
    }
  }, [onGenerationError, onGenerationSuccess, sendStatistic, setErrorPopout]);

  return {
    generatedImage,
    generateImage,
    setGeneratedImage,
  };
};
