import React from 'react';

import { Snackbar } from '@vkontakte/vkui';
import { Icon24CancelOutline, Icon28ErrorCircleOutline } from '@vkontakte/icons';

import styles from './ErrorSnackbar.module.css';

type ErrorSnackbarPropsType = {
  message: string;
  duration: number;
  onSnackbarClose: () => void;
  actionText?: string;
  onActionClick?: () => void;
};

export const ErrorSnackbar: React.FC<ErrorSnackbarPropsType> = ({
  message,
  duration,
  onSnackbarClose,
  actionText,
  onActionClick
}) => {
  const handleClickAction = () => {
    onSnackbarClose();
    onActionClick?.();
  };

  return (
    <Snackbar
      onClose={onSnackbarClose}
      duration={duration}
      action={actionText}
      onActionClick={handleClickAction}
      before={<Icon28ErrorCircleOutline fill='var(--vkui--color_icon_negative)' />}
      after={(
        <Icon24CancelOutline
          width={22}
          height={22}
          className={styles.cancelIcon}
          onClick={onSnackbarClose}
        />
      )}
    >
      {message}
    </Snackbar>
  );
};
