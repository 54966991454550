import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { SettingsType } from '../../types/settings.type';
import usersApi from '../../api/UsersApi';
import { isError } from '../../utils/isError';

import { SETTINGS_INITIAL_STATE } from './constants';

export const saveUserSettings = createAsyncThunk<Partial<SettingsType> | undefined, Partial<SettingsType>, { rejectValue: string }>(
  'settings/saveUserSettings',
  async function (settings, { rejectWithValue, dispatch }) {
    try {
      await usersApi.saveSettings(settings);

      return settings;
    }
    catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      };
    }
  }
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState: SETTINGS_INITIAL_STATE,
  reducers: {
    updateSettings(state, action: PayloadAction<object>) {
      Object.assign(state.data, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveUserSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveUserSettings.fulfilled, (state, action) => {
        Object.assign(state.data, action.payload || {});
        state.loading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { updateSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
