import { connect } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';

import { saveUserSettings } from '../../../store/settingsSlice';
import {
    selectHasProgressBar,
    selectHasInitialInstruction,
    selectAreNotificationsEnabled,
} from '../../../store/settingsSlice/selectors';
import { resetPopoutType, setPopoutType } from '../../../store/popoutSlice';
import { POPOUT } from '../../../constants/Popout';

import { DecorationSettings } from './DecorationSettings';

function mapStateToProps(state: RootState) {
    return {
        hasProgressbar: selectHasProgressBar(state),
        hasInitialInstruction: selectHasInitialInstruction(state)
    };
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return {
        changeSetting: async (option: string, value: boolean) => {
            dispatch(setPopoutType(POPOUT.SMART_SPINNER));
            await dispatch(saveUserSettings({ [option]: value }));
            dispatch(resetPopoutType());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DecorationSettings);
