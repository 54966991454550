import React from 'react';

import { Card } from '@vkontakte/vkui';

import { CardForScroll, UpgradedCardScroll } from '../cardScroll';

import styles from './Features.module.css';
import { FeaturesProps } from './types';
import { FeatureContent } from '../FeatureContent';

export const Features: React.FC<FeaturesProps> = ({ features, slideWidth, isDesktop }) => {
  return (
    isDesktop ? (
      <div className={styles.desktopContainer}>
        {
          features.map((feature, index) => (
            <Card mode="shadow">
              <FeatureContent feature={feature} needTwoListColumns />
            </Card>
          ))
        }
      </div>
    ) : (
      <UpgradedCardScroll needShowBullets slideWidth={slideWidth}>
        {
          features.map((feature, index) => (
            <CardForScroll isFirst={index === 0} isLast={index === features.length - 1}>
              <FeatureContent feature={feature} />
            </CardForScroll>
          ))
        }
      </UpgradedCardScroll>
    )
  );
};
