import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useRouter } from '../../../hooks/useRouter';

import { updateResult } from '../../../store/resultSlice';

import { selectFullNameByShortName } from '../../../store/testsSlice/testsSelectors';
import { selectResultByTestShortName } from '../../../store/resultsSlice/resultsSelectors';

import { PANEL } from '../../../constants/Panel';

import { ResultCard } from './ResultCard';

type ContainerPropsType = {
  testShortName: string;
  completionDate: string;
};

const ResultCardContainer: React.FC<ContainerPropsType> = (props) => {
  const { testShortName } = props;

  const router = useRouter();
  const dispatch = useAppDispatch();
  
  const testFullName = useSelector(
    (state: RootState) => selectFullNameByShortName(state, testShortName)
  );

  const result = useSelector(
    (state: RootState) => selectResultByTestShortName(state, testShortName)
  );

  const setResult = () => {
    dispatch(updateResult(result));
    router.forward({ panel: PANEL.RESULT });
  };

  return (
    <ResultCard
      {...props}
      testFullName={testFullName}
      setResult={setResult}
    />
  );
};

export default ResultCardContainer;
