import { getLaunchParams } from '../utils/getLaunchParams';

import { VIEW, ViewType } from './View';
import { PANEL, PanelType } from './Panel';
import { ModalType } from './Modal';

export const historyOperation = {
    PUSH: 'push',
    POP: 'pop',
    REPLACE: 'replace'
} as const;

export type HistoryOperationType = typeof historyOperation[keyof typeof historyOperation];

export type LocationType = {
    view: ViewType;
    panel: PanelType;
    modal: ModalType | null;
    length: number;
};

export const getDefaultLocation = () => {
    const { vk_are_notifications_enabled: enabled } = getLaunchParams();

    const location: LocationType = {
        view: enabled ? VIEW.TESTS : VIEW.NOTIFICATION,
        panel: enabled ? PANEL.TESTS : PANEL.NOTIFICATION,
        modal: null,
        length: 1
    };

    return location;
};

export const DEFAULT_LOCATION = getDefaultLocation();
