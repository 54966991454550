import { DEFAULT_LOCATION } from '../../constants/History';

import { RouterState } from './types';

export const ROUTER_SLICE_NAME = 'router';

export const ROUTER_INITIAL_STATE: RouterState = {
    data: {
        location: DEFAULT_LOCATION,
        operation: null
    },
};
