import React from 'react';
import { connect, useSelector } from 'react-redux';

import store, { RootState } from '../../../store';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { fetchTest } from '../../../store/testSlice';
import {
  selectAppearance,
  selectIsDesktop,
} from '../../../store/settingsSlice/selectors';

import { useRouter } from '../../../hooks/useRouter';
import { usePopout } from '../../../hooks/usePopout';

import { PANEL } from '../../../constants/Panel';
import { POPOUT } from '../../../constants/Popout';

import { TestCardContainerProps } from './TestCard.types';
import { TestCard } from './TestCard';
import { selectHasSkippableQuestions, selectPercentInfoByTestShortName } from '../../../store/testsSlice/testsSelectors';
import { VIEW } from '../../../constants/View';
import eventsApi from '../../../api/EventsApi';
import { selectResultData } from '../../../store/resultSlice/resultSliceSelectors';
import { EventType } from '../../../types/event.type';
import { selectUserVKID } from '../../../store/userSlice/userSelectors';

const TestCardContainer: React.FC<TestCardContainerProps> = (props) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const test = useSelector(selectResultData);
  const { setPopout, resetPopout } = usePopout();

  const testShortName = props.test.short_name;

  const percentInfo = useSelector((state: RootState) => selectPercentInfoByTestShortName(state, testShortName));
  const hasSkippableQuestions = useSelector((state: RootState) => selectHasSkippableQuestions(state, testShortName));

  const setTest = async (shortName: string) => {
    if (props.isRecommended){
      const route = {
        view: VIEW.PROFILE,
        panel: PANEL.RESULT,
        modal: null,
        length: 0,
        test_short_name: test?.test_short_name
      };

      const event: EventType = {
        vk_id: selectUserVKID(store.getState()) || 0,
        type: 'switch_to_recommended_test',
        name: `from_${test?.test_short_name}_to_${shortName}`,
        route: route,
      };

      eventsApi.sendStatistics(event);
    }

    setPopout(POPOUT.SMART_SPINNER);
    await dispatch(fetchTest(shortName));
    resetPopout();

    await router.forward({view: VIEW.TESTS, panel: PANEL.CURRENT_TEST});
  };

  return (
    <TestCard
      {...props}
      setTest={setTest}
      percentInfo={percentInfo}
      hasSkippableQuestions={hasSkippableQuestions}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    isDesktop: selectIsDesktop(state),
    appearance: selectAppearance(state),
  };
}

export default connect(mapStateToProps)(TestCardContainer);
