import React, { useState } from 'react';

import { Card, classNames, Headline, Tabs } from '@vkontakte/vkui';

import { CompactFactorDescription } from '../../CompactFactorDescription';
import { FactorType } from '../../../types';

import styles from './OtherFactorsDesktop.module.css';
import { TabCell } from './components/TabCell';
import './overrideVkui.css';
import { OtherFactorsDesktopProps } from './types';

export const OtherFactorsDesktop: React.FC<OtherFactorsDesktopProps> = ({ appearance, otherFactors }) => {
  const [factorToShow, setFactorToShow] = useState<FactorType>(otherFactors[0]);
  const feature = factorToShow.features.find(
    feature => feature?.show_with_description
  );

  return (
    <Card mode="shadow">
      <div className={styles.titleContainer}>
        <Headline weight="2" className={styles.title}>
                Другие типы личности
        </Headline>
      </div>
      <div className={classNames(styles.headerContainer, 'TabsHeader')}>
        <Tabs mode="accent" >
          {
            otherFactors.map(factor => {
              return (
                <TabCell
                  appearance={appearance}
                  title={factor.name.split(' ')[0]}
                  photoSrc={factor.character_images.preview.photo_src}
                  photoPlaceholderSrc={factor.character_images.preview.photo_placeholder}
                  selected={factor.name === factorToShow.name}
                  onClick={() => {
                    setFactorToShow(factor);
                  }}
                />
              );
            })
          }
        </Tabs>
      </div>
      <CompactFactorDescription
        isDesktop={true}
        photoPlaceholderSrc={factorToShow.character_images.preview.photo_placeholder}
        photoSrc={factorToShow.character_images.preview.photo_src}
        factorName={factorToShow.name}
        description={factorToShow.description}
        feature={feature}
      />
    </Card>
  );
};
