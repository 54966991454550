import { RefObject, useEffect, useState } from 'react';

export const useDimensions = <E, D>(ref: RefObject<E>, getDimensions: (element: E) => D) => {
  const [dimensions, setDimensions] = useState<D | undefined>(undefined);

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions(ref.current ? getDimensions(ref.current) : undefined);
    };

    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [getDimensions, ref]);

  return dimensions;
};
