import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useRouter } from '../../../hooks/useRouter';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { selectIsDesktop } from '../../../store/settingsSlice/selectors';
import { MODAL } from '../../../constants/Modal';
import { EVENT_NAME } from '../../../constants/Event';
import { OUTPUT_HANDLER } from '../../../constants/OutputHandler';
import { updateDataByHandlerId } from '../../../store/resultSlice';
import { selectUserOriginalPhoto } from '../../../store/userSlice/userSelectors';
import { getRandomItem } from '../../../utils/getRandomItem';

import { GollandHandler } from './GollandHandler';
import { GollandHandlerContainerType } from './types';
import { useGenerateImage, useSendStatisticDuringGeneration } from './hooks';
import compareFactors from './utils/compareFactors';

const GollandHandlerContainer: React.FC<GollandHandlerContainerType> = ({
  factors,
  needGenerateImage
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isDesktop = useSelector(selectIsDesktop);
  const userPhotoUrl = useSelector(selectUserOriginalPhoto);
  const sortedFactors = useMemo(() => [...factors].sort(compareFactors), [factors]);
  const factorToShow = useMemo(() => sortedFactors[0], [sortedFactors]);
  const sendStatistic = useSendStatisticDuringGeneration();
  const [needRegeneration, setNeedRegeneration] = useState(false);
  const [wasThereGeneration, setWasThereGeneration] = useState(false);
  const onGenerationSuccess = useCallback(() => {
    setNeedRegeneration(true);
    setWasThereGeneration(true);
  }, []);
  const onGenerationError = useCallback(() => {
    setNeedRegeneration(false);
    setWasThereGeneration(true);
  }, []);

  const {
    generatedImage: imageToShow,
    setGeneratedImage: setImageToShow,
    generateImage,
  } = useGenerateImage(onGenerationSuccess, onGenerationError);

  useEffect(() => {
    if (needGenerateImage) {
      generateImage(factorToShow, userPhotoUrl);
    } else {
      setImageToShow(getRandomItem(factorToShow.character_images.to_generate));
    }
  }, []);

  useEffect(() => {
    dispatch(updateDataByHandlerId({
      output: OUTPUT_HANDLER.MODAL,
      data: { factor: factorToShow, imageToShow }
    }));
  }, [imageToShow, factorToShow, dispatch]);

  const shareResult = () => {
    router.forward({ modal: MODAL.SHARE_GOLLAND_RESULT });
  };

  const showInfoAboutTest = () => {
    router.forward({ modal: MODAL.ABOUT_GOLLAND });
  };

  const handleGenerationClick = () => {
    generateImage(factorToShow, userPhotoUrl);
    if (needRegeneration) {
      sendStatistic(EVENT_NAME.GOLLAND_CLICK_REGENERATE_BUTTON);
    } else {
      sendStatistic(EVENT_NAME.GOLLAND_CLICK_GENERATE_BUTTON);
    }
  };

  return (
    <GollandHandler
      needRegeneration={needRegeneration}
      showGenerationButton={needRegeneration || (!needGenerateImage && !wasThereGeneration)}
      onGenerateButtonClick={handleGenerationClick}
      showInfoAboutTest={showInfoAboutTest}
      shareResult={shareResult}
      isDesktop={isDesktop}
      imageSrc={imageToShow}
      factors={sortedFactors}
      factorToShow={factorToShow}
    />
  );
};

export default GollandHandlerContainer;
