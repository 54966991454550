import React from 'react';

import {
  AppRoot,
  Epic,
  ModalRoot,
  PanelHeader,
  SplitCol,
  SplitLayout,
} from '@vkontakte/vkui';

import { VIEW, ViewType } from '../constants/View';
import { PanelType, isPanelWithNavigation } from '../constants/Panel';
import { MODAL, ModalType } from '../constants/Modal';
import { NAVIGATION } from '../constants/Navigation';

import { usePopout } from '../hooks/usePopout';

import { MobileNavigation } from '../components/navigation/MobileNavigation';

import { InstructionModal } from './tests/modals/InstructionModal';
import { AboutUsModal } from './settings/modals/AboutUsModal';

import { TestsView } from './tests';
import { ProfileView } from './profile';
import { SettingsView } from './settings';
import { NotificationView } from './notification';

import styles from './MainViews.module.css';
import { ShareGollandResultModal } from '../components/outputHandlers/GollandHandler/modals/ShareResultModal';
import { AboutGollandTestModal } from '../components/outputHandlers/GollandHandler/modals/AboutGollandTestModal';

export type MainViewsPropsType = {
  isDesktop: boolean;
  hasHeader: boolean;
  activeView: ViewType;
  activePanel: PanelType;
  activeModal: ModalType | null;
  onModalClose: () => void;
};

export const MainViews: React.FC<MainViewsPropsType> = ({
  isDesktop,
  hasHeader,
  activeView,
  activePanel,
  activeModal,
  onModalClose,
}) => {

  const modal = (
    <ModalRoot
      activeModal={activeModal}
      onClose={onModalClose}
    >
      <InstructionModal id={MODAL.INSTRUCTION} />
      <AboutUsModal id={MODAL.ABOUT_US} />
      <ShareGollandResultModal id={MODAL.SHARE_GOLLAND_RESULT} />
      <AboutGollandTestModal id={MODAL.ABOUT_GOLLAND} />
    </ModalRoot>
  );

  const { popout } = usePopout();

  return (
    <AppRoot>
      <SplitLayout
        className={styles.MainViews}
        header={hasHeader && <PanelHeader separator={false} />}
        popout={popout}
        modal={modal}
      >
        <SplitCol
          animate={!isDesktop}
          autoSpaced
          width='100%'
          maxWidth='745px'
        >
          <Epic
            activeStory={activeView}
            tabbar={!isDesktop && isPanelWithNavigation(activePanel) && (
              <MobileNavigation id={NAVIGATION.MOBILE} />
            )}
          >
            <TestsView id={VIEW.TESTS} />
            <ProfileView id={VIEW.PROFILE} />
            <SettingsView id={VIEW.SETTINGS}/>
            <NotificationView id={VIEW.NOTIFICATION} />
          </Epic>
        </SplitCol>
      </SplitLayout>
    </AppRoot>
  );
};
