import api from './api';
import { AppConfig } from '../types/appconfig.type';

export const getAppConfig = async (): Promise<AppConfig | null> => {
    try {
        const config = await api('configs/available');

        return config;
    } catch {
        return null;
    }
};
