import { connect } from 'react-redux';
import { RootState } from '../../../store';

import { selectUserAccessToken } from '../../../store/userSlice/userSelectors';

import { FriendHandler } from './FriendHandler';
import { getMutualFriends } from '../../../api/VKWebApi';

function mapStateToProps(state: RootState) {
  const accessToken = selectUserAccessToken(state);

  return {
    getMutualFriends: (targetUids: number | number[]) => {
      if (!accessToken) {
        throw new Error('ACCESS_TOKEN_REQUIRED');
      }
      return getMutualFriends(accessToken, targetUids);
    }
  };
}

export default connect(mapStateToProps)(FriendHandler);
