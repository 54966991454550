import React, { useEffect, useMemo, useState } from 'react';

import { Avatar, Caption, classNames, TabsItem } from '@vkontakte/vkui';

import ProgressiveImage from 'react-progressive-graceful-image';

import styles from './TabCell.module.css';
import { TabCellProps } from './types';
import { DARK_ACCENT_BORDER_COLOR, DARK_ACCENT_COLOR, DARK_SECONDARY_COLOR } from './constants';

export const TabCell: React.FC<TabCellProps> = ({
  title,
  photoSrc,
  selected,
  onClick,
  photoPlaceholderSrc,
  appearance
}) => {
  const [hasMouseOver, setHasMouseOver] = useState(false);
  const isDark = useMemo(() => appearance === 'dark', [appearance]);

  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(undefined);
  const [borderColor, setBorderColor] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isDark) {
      setBorderColor(selected ? DARK_ACCENT_BORDER_COLOR : 'transparent');
      if (hasMouseOver) {
        setBackgroundColor(DARK_SECONDARY_COLOR);
      } else {
        setBackgroundColor(selected ? DARK_ACCENT_COLOR : undefined);
      }
    }
  }, [hasMouseOver, isDark, selected]);

  return (
    <TabsItem
      selected={selected}
      disabled={false}
      onClick={onClick}
      className={classNames(
        styles.container,
        isDark ? styles.containerDark : null,
      )}
      style={{
        marginLeft: 0,
        backgroundColor,
        border: isDark ? `1px solid ${borderColor}` : 'none',
      }}
      onMouseOver={() => {
        setHasMouseOver(true);
      }}
      onMouseLeave={() => {
        setHasMouseOver(false);
      }}
    >
      <div className={styles.contentContainer}>
        <div className={styles.avatarContainer}>
          <ProgressiveImage
            src={photoSrc}
            placeholder={photoPlaceholderSrc}
          >
            {(src, loading) => (
              <div>
                <Avatar
                  size={56}
                  src={src}
                  style={{ filter: loading ? 'blur(8px)' : 'blur(0)' }}
                />
              </div>
            )}
          </ProgressiveImage>
        </div>
        <div className={styles.textContainer}>
          <Caption className={styles.text} style={{ fontSize: 11.5 }}>
            {title}
          </Caption>
        </div>
      </div>
    </TabsItem>
  );
};
