import { getLaunchParams } from '../../utils/getLaunchParams';

import { SettingsState } from './types';

export const SETTINGS_INITIAL_STATE: SettingsState = {
    data: {
        appearance: 'light',
        platform: 'android',
        isDesktop: false,
        hasHeader: true,
        // TODO: придумать что-то со смешением кэмелкейса и снейккейса
        has_progressbar: true,
        has_initial_instruction: true,
        are_notifications_enabled: getLaunchParams().vk_are_notifications_enabled
    },
    loading: false,
    error: null,
};
