export const cacheImages = async (srcList: string[]) => {
  if (!srcList.length) {
    return;
  }

  const promises = srcList.map((src) => {
    return new Promise(function(resolve, reject) {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(undefined);
      img.onerror = () => reject();
    });
  });

  await Promise.all(promises);
};
