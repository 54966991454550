import React from 'react';

import {
  Div,
  ModalPage,
  ModalPageHeader,
  PanelHeaderButton,
  Platform,
  Title,
  Text,
  SimpleCell,
} from '@vkontakte/vkui';
import {
  Icon24Cancel,
  Icon28GlobeOutline,
} from '@vkontakte/icons';

import { ModalType } from '../../../../constants/Modal';

export type AboutUsModalPropsType = {
  id: ModalType;
  platform: string;
  isDesktop: boolean;
  onBackClick: () => void;
  openURL: (URL: string) => void;
};

export const AboutUsModal: React.FC<AboutUsModalPropsType> = ({
  id,
  platform,
  isDesktop,
  onBackClick,
  openURL,
}) => {
  return (
    <ModalPage
      id={id}
      header={(
        <ModalPageHeader
          before={(
            <>
              {!isDesktop && (platform === Platform.ANDROID || platform === Platform.VKCOM) && (
                <PanelHeaderButton
                  onClick={onBackClick}
                  aria-label='Закрыть инструкцию'
                >
                  <Icon24Cancel />
                </PanelHeaderButton>
              )}
            </>
          )}
          after={(
            <>
              {platform === Platform.IOS && (
                <PanelHeaderButton
                  onClick={onBackClick}
                  aria-label='Закрыть инструкцию'
                >
                  Готово
                </PanelHeaderButton>
              )}
            </>
          )}
        >
          О нас
        </ModalPageHeader>
      )}
    >
      <Div style={{ paddingTop: '0.5em' }}>
        <Title level='1' style={{ marginBottom: 8 }}>ТиМПИ —</Title>
        <Text style={{ marginBottom: 16 }}>Лаборатория Теоретических и Междисциплинарных Проблем Информатики</Text>
        <Title level='2'>TICS.LAB</Title>
        <SimpleCell
          before={<Icon28GlobeOutline />}
          style={{ padding: '0 0.6rem' }}
          onClick={() => openURL('https://dscs.pro')}
        >
          Сайт лаборатории
        </SimpleCell>
      </Div>
    </ModalPage>
  );
};
