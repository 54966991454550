export const PANEL = {
  TESTS: 'tests',
  CURRENT_TEST: 'currentTest',
  ADDING_TEST_FORM: 'addingTestForm',
  PROFILE: 'profile',
  RESULT: 'result',
  SETTINGS: 'settings',
  NOTIFICATION: 'notification',
  ERROR: 'error'
} as const;

export type PanelType = typeof PANEL[keyof typeof PANEL];

export const panelsWithNavigation = [
  PANEL.TESTS,
  PANEL.PROFILE,
  PANEL.SETTINGS,
];

export const isPanelWithNavigation = (activePanel: PanelType) => {
  return panelsWithNavigation.some((panel) => panel === activePanel);
};
