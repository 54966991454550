import React from 'react';

import { CardGrid, ContentCard } from '@vkontakte/vkui';
import ProgressiveImage from 'react-progressive-graceful-image';
import ReactMarkdown from 'react-markdown';

import styles from './OneFactorHandler.module.css';

export type OneFactorHandlerType = {
  factor: {
    picture: string;
    picturePlaceholder?: string,
    alt: string;
    width?: string | number;
    height?: string | number;
    name: string;
    description?: string;
  };
  isDesktop: boolean;
};

export const OneFactorHandler: React.FC<OneFactorHandlerType> = ({
  factor: {
    picture,
    picturePlaceholder,
    alt,
    width,
    height,
    name,
    description,
  },
  isDesktop,
}) => {
  return (
    <CardGrid size='l'>
      <ProgressiveImage
        src={picture}
        placeholder={picturePlaceholder}
      >
        {(src, loading) => (
          <img
            src={src}
            alt={alt}
            style={{
              display: 'block',
              width: !isDesktop ? '100%' : (width || '100%'),
              height: !isDesktop ? '100%' : (height || '100%'),
              marginLeft: 'auto',
              marginRight: 'auto',
              filter: loading ? 'blur(8px)' : 'blur(0)'
            }}
          />
        )}
      </ProgressiveImage>
      <ContentCard
        header={name}
        caption={description && (
          <ReactMarkdown className={styles.markdown}>
            {description}
          </ReactMarkdown>
        )}
        mode='outline'
      />
    </CardGrid>
  );
};
