import React from 'react';

import {
  Avatar,
  Div,
  Title,
  Text,
  RichCell,
  calcInitialsAvatarColor,
} from '@vkontakte/vkui';

import { getInitialLetters } from '../../../utils/getInitialLetters';

export type ProfilePropsType = {
  vkId: number;
  fullName: string;
  firstEntryDate?: string;
  photo?: string;
};

export const Profile: React.FC<ProfilePropsType> = ({
  vkId,
  fullName,
  firstEntryDate,
  photo,
}) => {
  const renderFullName = () => (
    <Title level='3'>
      {fullName}
    </Title>
  );

  const renderAvatar = () => (
    <Avatar
      size={72}
      src={photo}
      initials={getInitialLetters(fullName)}
      gradientColor={calcInitialsAvatarColor(vkId)}
    />
  );

  const renderFirstEntryDate = () => {
    if (!firstEntryDate) {
      return;
    }

    return (
      <Text style={{ color: 'var(--vkui--color_text_secondary)' }}>
        {`В приложении с ${firstEntryDate}`}
      </Text>
    );
  };

  return (
    <Div>
      <RichCell
        disabled
        multiline
        before={renderAvatar()}
        caption={renderFirstEntryDate()}
      >
        {renderFullName()}
      </RichCell>
    </Div>
  );
};
