import React from 'react';
import {
  Tabbar,
  TabbarItem
} from '@vkontakte/vkui';

import { ViewType } from '../../../constants/View';

import { EpicMouseEvent } from './MobileNavigation.connect';

export type MobileNavigationOptionType = {
  value: string | number | undefined;
  text: string | undefined;
  label: React.ReactElement;
  action: (event: EpicMouseEvent) => void;
};

export type MobileNavigationRestType = {
  activeView: ViewType;
  id: string;
};

export type MobileNavigationPropsType = { options: MobileNavigationOptionType[] } & MobileNavigationRestType;

export const MobileNavigation: React.FC<MobileNavigationPropsType> = ({
  options,
  activeView,
  id,
}) => {
  return (
    <Tabbar id={id}>
      {options.map(({ value, text, label, action }) => (
        <TabbarItem
          onClick={action}
          selected={value === activeView}
          data-story={value}
          text={text}
          key={value}
        >
          <>{label}</>
        </TabbarItem>
      ))}
    </Tabbar>
  );
};
