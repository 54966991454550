import { connect } from 'react-redux';

import { RootState } from '../../store';
import { selectPanel } from '../../store/routerSlice/routerSelectors';

import { SettingsView } from './SettingsView';

function mapStateToProps(state: RootState) {
  return {
    activePanel: selectPanel(state),
  };
}

export default connect(mapStateToProps)(SettingsView);
