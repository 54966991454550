import React from 'react';
import { useSelector } from 'react-redux';

import { selectAvailableAnnouncement } from '../../../store/appConfigSlice/selectors';

import { Announcement } from './Announcement';

const AnnouncementContainer: React.FC = () => {
    const announcement = useSelector(selectAvailableAnnouncement);

    if (!announcement) {
        return null;
    }

    return <Announcement {...announcement} />;
};

export default AnnouncementContainer;
