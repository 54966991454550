import { connect } from 'react-redux';
import bridge from '@vkontakte/vk-bridge';

import { RootState } from '../../../store';

import { selectAppId } from '../../../store/launchParamsSlice/selectors';
import { selectUserVKID } from '../../../store/userSlice/userSelectors';
import { selectLocation } from '../../../store/routerSlice/routerSelectors';
import { selectTestShortName } from '../../../store/testSlice/testSelectors';

import eventsApi from '../../../api/EventsApi';

import { EVENT_TYPE, EVENT_NAME } from '../../../constants/Event';

import { AppShare } from './AppShare';

function mapStateToProps(state: RootState) {
  const appId = selectAppId(state);

  const location = selectLocation(state);
  const testShortName = selectTestShortName(state);
  const vkId = selectUserVKID(state);

  const shareApp = () => {
    bridge
      .send('VKWebAppShowWallPostBox', {
        message: 'Моё любимое приложение с психологическими (и не только) тестами!',
        attachments: `https://vk.com/${Boolean(appId) ? `app${appId}` : 'ticspsytests'}`,
      })
      .then((data) => {
        if (!vkId) {
          return;
        }

        eventsApi.sendStatistics({
          type: EVENT_TYPE.REPOST,
          name: EVENT_NAME.SHARE_APP_SUCCESS,
          data,
          vk_id: vkId,
          route: {
            ...location,
            test_short_name: testShortName,
          },
        });
      })
      .catch((error) => {
        if (!vkId) {
          return;
        }
        eventsApi.sendStatistics({
          type: EVENT_TYPE.REPOST,
          name: EVENT_NAME.SHARE_APP_FAILURE,
          data: error,
          vk_id: vkId,
          route: {
            ...location,
            test_short_name: testShortName,
          },
        }); 
      });
  };

  return {
    shareApp,
  };
}

export default connect(mapStateToProps)(AppShare);
