import React from 'react';
import ReactMarkdown from 'react-markdown';

import {
  Div,
  Title,
  Gallery,
} from '@vkontakte/vkui';

import { QuestionProps } from './Question.types';

import styles from './Question.module.css';

export const Question: React.FC<QuestionProps> = ({ question, isDesktop, questionNumber }) => {
  const {
    content,
    photos,
  } = question;

  return (
    <>
      <Div>
        <Title level='3'>Вопрос №{questionNumber}</Title>
        <ReactMarkdown>
          {content}
        </ReactMarkdown>
        {Boolean(photos) && (
          <Div style={{ textAlign: 'center' }}>
            <Gallery align='center' showArrows>
              {photos?.map(({ url, scale, mobile_scale }, index) => {
                const size = isDesktop ? `${scale ?? 100}%` : `${mobile_scale ?? 100}%`;
                return (
                  <img
                    key={index}
                    className={styles.galleryImage}
                    alt={`question${questionNumber}-${index}`}
                    src={url}
                    style={{ 
                      width: size,
                      height: size,
                    }}
                  />
                );
              })}
            </Gallery>
          </Div>
        )}
      </Div>
    </>
  );
};
