import React from 'react';
import { Banner, Button, Image } from '@vkontakte/vkui';

import { Announcement as AnnouncementProps } from '../../../types/announcement.type';

import { useClosed } from './hooks';

export const Announcement: React.FC<AnnouncementProps> = ({
    id,
    header,
    subheader,
    text,
    size,
    action,
    closable,
    image
}) => {
    const { isClosed, handleClose } = useClosed(id);

    if (isClosed) {
        return null;
    }

    return (
        <Banner
            mode="tint"
            header={header}
            subheader={subheader}
            text={text}
            size={size}
            actions={Boolean(action) && (
                <Button
                    as="a"
                    href={action?.href}
                    target="_blank"
                    mode={action?.mode}
                    size={action?.size}
                >
                    {action?.text}
                </Button>
            )}
            asideMode={closable ? 'dismiss' : undefined}
            onDismiss={handleClose}
            before={Boolean(image) && (
                <Image src={image?.src} size={image?.size} />
            )}
        />
    );
};
