import { LaunchParams } from '../types/launchparams.type';

const convertToString = (value: unknown) => String(value);
const convertToNumber = (value: unknown) => Number(value);
const convertToBoolean = (value: unknown) => Boolean(Number(value));
const convertToArray = (value: string) => value.split(',');

const convertMap: Record<
    keyof LaunchParams,
        | typeof convertToString
        | typeof convertToNumber
        | typeof convertToBoolean
        | typeof convertToArray
> = {
    vk_user_id: convertToNumber,
    vk_app_id: convertToNumber,
    vk_is_app_user: convertToBoolean,
    vk_are_notifications_enabled: convertToBoolean,
    vk_language: convertToString,
    vk_ref: convertToString,
    vk_access_token_settings: convertToArray,
    vk_group_id: convertToNumber,
    vk_viewer_group_role: convertToString,
    vk_platform: convertToString,
    vk_is_favorite: convertToBoolean,
    vk_ts: convertToNumber,
    sign: convertToString
} as const;

export const getLaunchParams = () => {
    const rawParams = window.location.search.startsWith('?')
        ? window.location.search.slice(1)
        : window.location.search;

    const preparedParams = rawParams.split('&').reduce<LaunchParams>(
        (params, param) => {
            const [key, value] = param.split('=');

            const convert = convertMap.hasOwnProperty(key)
                ? convertMap[key as keyof LaunchParams]
                : convertToString;

            // @ts-ignore
            params[key] = convert(value);

            return params;
        },
        {} as LaunchParams
    );

    return preparedParams;
};
