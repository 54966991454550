import bridge from '@vkontakte/vk-bridge';
import { prepareError } from '../../utils/prepareError';
import { getAlbumId, getCallApiMethod, getFromVkStorage, uploadImageToVkServers } from './utils';
import { ALBUM_ID_STORAGE_KEY } from './constants';

export const getMutualFriends = async (
  accessToken: string,
  targetUids: number | number[],
  sourceUid?: number,
) => {
  const { response } = await bridge.send('VKWebAppCallAPIMethod', {
    method: 'friends.getMutual',
    request_id: 'tics42',
    params: {
      v: '5.131',
      access_token: accessToken,
      target_uids: Array.isArray(targetUids) ? targetUids.join() : targetUids,
      ...(sourceUid) && { source_uid: sourceUid }
    },
  });

  return response;
};

export const addImageToUser = async (
  accessToken: string,
  image: Blob,
  imageHash: string,
  albumTitle: string,
  albumDescription: string,
) => {
  const callApiMethod = getCallApiMethod(accessToken);
  try {
    const albumId = await getAlbumId(ALBUM_ID_STORAGE_KEY, albumTitle, albumDescription, callApiMethod);

    // Проверяем, сохранены ли уже данные о картинке (owner_id, id) в vk storage
    let savedPhotoData = await getFromVkStorage(imageHash, callApiMethod);
    if (savedPhotoData) {
      const [owner_id, id] = savedPhotoData.split(',');

      // Проверяем, не удалил ли пользователь фото
      const { response: { items } } = await callApiMethod('photos.get', {
        album_id: albumId,
        photo_ids: id
      });
      if (items.length > 0) {
        // Если фото не удалено, то возвращаем данные
        return { owner_id, id };
      }
    }

    // Загружаем картинку на сервера ВК
    const { server, photos_list, hash } = await uploadImageToVkServers(image, albumId, callApiMethod);

    // Загружаем картинку в альбом пользователя
    const { response: photosData }: any = await callApiMethod('photos.save', {
      album_id: albumId,
      server,
      photos_list,
      hash
    });

    const { id, owner_id } = photosData[0];

    // Сохраняем данные о картинке в vk storage
    callApiMethod('storage.set', { key: imageHash, value: [owner_id, id].join() });

    return { id, owner_id };
  } catch (error) {
    throw prepareError(error);
  }
};
