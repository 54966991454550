import React from 'react';
import { useSelector } from 'react-redux';

import { ModalType } from '../../../../../constants/Modal';
import { useRouter } from '../../../../../hooks/useRouter';
import { selectIsDesktop } from '../../../../../store/settingsSlice/selectors';

import { AboutGollandTestModal } from './AboutGollandTestModal';

type AboutGollandTestModalContainerProps = {
  id: ModalType;
};

const AboutGollandTestModalContainer: React.FC<AboutGollandTestModalContainerProps> = (props) => {
  const router = useRouter();
  const isDesktop = useSelector(selectIsDesktop);

  return (
    <AboutGollandTestModal
      {...props}
      isDesktop={isDesktop}
      onBackClick={router.back}
    />
  );
};

export default AboutGollandTestModalContainer;
