import React from 'react';

import {
  Checkbox,
  FormLayout,
} from '@vkontakte/vkui';

import { QuestionHandlerPropsType } from '../../test/Answer/Answer';

import { Form } from '../FormHandler/Form';

type CheckboxAnswerType = {
  a_id: number;
};

export const CheckboxHandler: React.FC<QuestionHandlerPropsType> = ({
  answer,
  possibleAnswers,
  givenAnswers,
  metaInfo,
}) => {
  const onCheckboxChange = (currentAnswer: CheckboxAnswerType) => {
    const index = (givenAnswers as CheckboxAnswerType[]).findIndex((givenAnswer) => givenAnswer.a_id === currentAnswer.a_id);

    const newGivenAnswers = index === -1
      ? [...givenAnswers, currentAnswer]
      : [...givenAnswers.slice(0, index), ...givenAnswers.slice(index + 1)];

    answer(newGivenAnswers);
  };

  const isChecked = (answerId: number) => {
    return Boolean((givenAnswers as CheckboxAnswerType[]).filter(({ a_id }) => a_id === answerId).length);
  };

  return (
    <FormLayout>
      {possibleAnswers.map((possibleAnswer, index) => (
        <React.Fragment key={possibleAnswer.a_id}>
          {((index < possibleAnswers.length - 1) || !metaInfo?.with_form) ? (
            <Checkbox
              description={possibleAnswer.description}
              checked={isChecked(possibleAnswer.a_id)}
              onChange={() => onCheckboxChange({ a_id: possibleAnswer.a_id })}
            >
              {possibleAnswer.content}
            </Checkbox>
          ) : (
            <Form
              possibleAnswer={possibleAnswer}
              givenAnswers={givenAnswers}
              answer={answer}
            />
          )}
        </React.Fragment>
      ))}
    </FormLayout>
  );
};
