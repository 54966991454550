import React, { ReactNode } from 'react';

import { Placeholder } from '@vkontakte/vkui';
import { Icon24SadFaceOutline } from '@vkontakte/icons';

export type ErrorPlaceholderPropsType = {
  children?: ReactNode;
  header: ReactNode;
};

export const ErrorPlaceholder: React.FC<ErrorPlaceholderPropsType> = ({
  children = 'Попробуйте перезагрузить приложение',
  header,
}) => {
  return (
    <Placeholder
      icon={<Icon24SadFaceOutline width={56} height={56} />}
      header={header}
    >
      {children}
    </Placeholder>
  );
};
