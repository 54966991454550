import React from 'react';

import { Div, Placeholder } from '@vkontakte/vkui';
import { Icon24SadFaceOutline } from '@vkontakte/icons';

export type ErrorHandlerType = {
  header?: string;
  content?: string;
};

const ERROR_CONTENT = 'При формировании результатов возникла непредвиденная ошибка. Пожалуйста, пройдите тест ещё раз';

export const ErrorHandler: React.FC<ErrorHandlerType> = ({
  header,
  content = ERROR_CONTENT,
}) => {
  return (
    <Div>
      <Placeholder
        icon={<Icon24SadFaceOutline width={56} height={56}/>}
        header={header}
      >
        {content}
      </Placeholder>
    </Div>
  );
};
